﻿.subscribe-block {
  opacity: 0;
  transition: opacity 0.3s ease;

  .error-span {
    color: #a71a1e;
  }

  &.is-active {
    opacity: 1;
  }

  @include breakpoint(large) {
  }

  &__wrapper {
    display: flex;
    padding: 3rem 1rem;
    background: #f3f5f8;
    justify-content: center;
    position: relative;
    transition: height 0.3s ease;

    &__loading {
      position: absolute;
      transition: opacity 0.2s ease;
      background: rgba(255, 255, 255, 0.83);
      width: 100%;
      opacity: 0;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -999;
      display: flex;
      align-items: center;
      justify-content: center;

      &.is-active {
        z-index: 999;
        opacity: 1;
      }
    }

    @include breakpoint(medium) {
      padding: 0;
      justify-content: flex-start;
    }

    .error {
      border: solid 1px #a71a1e !important;
      box-shadow: none !important;
      margin-bottom: 0 !important;
      background: url(/static/images/icon-varning.svg) no-repeat scroll 7px 7px;
      padding-right: 40px;
      background-position: right 8px center;

      &-span {
        color: #a71a1e;
        font-size: 14px;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      & + .error-span {
        opacity: 1;
      }
    }

    .basic-multi {
      &-select {
        margin: 0 0 1rem 0;
      }

      &__control {
        border-radius: 4px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      &__input {
        input[type="text"] {
          height: 37px;
          margin: 0;
          border-radius: 0;
          box-shadow: none;
        }
      }

      &__indicators {
        cursor: pointer;
      }

      &__dropdown-indicator {
        display: block;
        width: 40px;
        height: 40px;
        background-image: url(/static/images/icon-form-arrow.svg);
        background-repeat: no-repeat;
        margin-top: 0;
        padding: 0;
        transform: translate(9px, 14px);

        svg {
          display: none;
        }
      }
    }

    .subscribe-label {
      color: $dark-blue;
      font-weight: bold;
      font-size: 16px;
      font-family: "Source Sans Pro", sans-serif;

      @include breakpoint(medium) {
        font-size: 20px;
      }
    }

    &__ok {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include breakpoint(medium) {
        padding: 4rem 1rem;
        margin: 0 auto;
      }

      &__header {
        margin-bottom: 15px;
        transform: translateY(3rem);
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.3s ease;

        .ok-active & {
          opacity: 1;
          transform: translateY(0);
        }
      }

      &__text {
        transform: translateY(3rem);
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.3s ease 0.1s;

        .ok-active & {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &__left {
      display: none;
      background-color: #e6ebf1;

      @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
        flex-basis: 35%;
        align-items: flex-end;
        padding: 55px 1rem 55px 2rem;
      }

      &__upper {
        height: 70px;

        &__disclaimer {
          margin-bottom: 0;
          color: $dark-blue;
        }
      }

      &__bottom {
        &__label {
          margin: 2px 0 0 0;
          text-align: right;

          & + & {
            margin-top: 1.5rem;
          }
        }
      }
    }

    &__right {
      flex-grow: 1;

      @include breakpoint(medium) {
        padding: 55px 1rem 55px 2rem;
      }

      &__upper {
        height: 70px;

        &__header {
          line-height: 0.7;

          @include breakpoint(medium) {
            font-size: 30px;
          }
        }

        &__label {
          display: flex;

          @include breakpoint(medium) {
            display: none;
          }
        }
      }

      &__bottom {
        max-width: 571px;

        input[type="text"] {
          border-radius: 4px;
          box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
          background-color: #ffffff;
          border-bottom: none;
          border-left: none;
          border-right: none;

          &.short {
            max-width: 372px;
          }
        }

        input[type="submit"] {
          width: 199px;
          height: 40px;
          border-radius: 27px;
          border: solid 3px #0095f5;
          background-color: #0095f5;
          font-size: 15px;
          color: #fff;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: bold;
          cursor: pointer;
          transition: 0.2s ease all;

          :disabled {
            cursor: not-allowed !important;
            background-color: #e6e6e6 !important;
          }

          &:focus,
          &:hover {
            background: $dark-blue;
            color: #fff;
            border-color: $dark-blue;
          }
        }

        input[disabled] {
          background-color: #a2a2a2;
          cursor: not-allowed;
          border-color: #a2a2a2 !important;

          &:focus,
          &:hover {
            background: #a2a2a2 !important;
            border-color: #a2a2a2 !important;
          }
        }

        &__category-wrapper {
          margin: 2rem 0;

          &__header {
            font-size: 20px;
            font-weight: bold;
            color: $dark-blue;
            font-family: "Source Sans Pro", sans-serif;
          }
        }
      }
    }
  }
}
