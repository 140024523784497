.standard-page-wrapper {
  &__top {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 82px 0;

    &--left {
      @include breakpoint(medium) {
        &.flex-basis {
          flex-basis: 75%;
        }
      }
      flex-basis: 100%;

      .ingress {
        max-width: 815px;
      }
    }

    &--right {
      @include breakpoint(medium) {
        display: flex;
      }
      display: none;
      padding-left: 25px;
      flex-basis: 25%;
      justify-content: flex-end;
    }
    @include breakpoint(medium) {
      padding-top: 30px;
    }

    @include breakpoint(large) {
      // width: 870px;
      padding: 50px 0;
    }
  }
}
.error-page {
  .standard-page-wrapper__top {
    flex-direction: column;
  }
}
