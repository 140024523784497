.icon-links {
  background-color: #f3f5f8;
}
.c-icon-link-block__divider {
  &:first-of-type {
    display: none;
  }
}

.c-icon-link-block {
  padding: 0;

  @include breakpoint(medium) {
    padding: 0 40px;
    justify-content: space-between;
    margin-bottom: 0;
  }
  @include breakpoint(large) {
    padding: 0;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  &__background {
    width: 50px;
    height: 50px;
    min-width: 50px;
    background: $cream;
    border-radius: 50%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    @include breakpoint(medium) {
      margin-left: 0;
      width: 70px;
      height: 70px;
      min-width: 70px;
      font-size: 33px;
    }

    @include breakpoint(large) {
      width: 80px;
      height: 80px;
      min-width: 80px;
      font-size: 36px;
    }

    &.dark-blue {
      background: $dark-blue !important;
      color: #fff;
    }

    &.light-blue {
      background: $light-blue !important;
      color: #fff;
    }

    &.medium-blue {
      background: $medium-blue !important;
      color: #fff;
    }
    &.teal {
      background: $teal !important;
      color: #fff;
    }

    &.olive {
      background: $olive !important;
      color: #fff;
    }
  }

  &__divider {
    display: none;
    height: 50px;
    width: 1px;
    margin: 0 25px;
    background: #b6c4d8;

    @include breakpoint(large) {
      display: block;
    }
  }

  &__link-block,
  &__link-block__wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: row;
    border-top: 1px solid #e9eff6;
    align-items: center;
    padding: 15px 0;

    &:hover,
    &:focus {
      .c-icon-link-block__link-block__text:after {
        opacity: 1;
      }
    }

    @include breakpoint(medium) {
      margin: 20px 0;
      border-top: 0;
      margin: 20px 0;
      padding: 0;
      flex: 1 1 50%;
      padding: 0 20px;
    }
    @include breakpoint(large) {
      width: calc(25% - 39px);
      flex: none;
      padding: 0;
    }
    &__text {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      color: $dark-blue;
      font-family: Source Sans Pro;
      letter-spacing: 0.75px;
      transition: 0.3s ease all;
      display: flex;
      text-align: left;
      padding-left: 1.5rem;

      margin-right: 20px;
      flex-direction: column;
      display: flex;
      @include breakpoint(medium) {
        max-width: 173px;
        margin-right: 0;
      }
      &:after {
        content: "";
        width: 0px;
        height: 2px;
        display: block;
        background: $dark-blue;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease all;
      }
    }

    &__icon,
    &__background {
      // pointer-events: none;
      cursor: pointer;
      width: 50px;
      height: 50px;
      min-width: 50px;
      background: $cream;
      border-radius: 50%;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;

      @include breakpoint(medium) {
        margin-left: 0;
        width: 70px;
        height: 70px;
        min-width: 70px;
        font-size: 33px;
      }

      @include breakpoint(large) {
        width: 80px;
        height: 80px;
        min-width: 80px;
        font-size: 36px;
      }

      &.dark-blue {
        background: $dark-blue !important;
        color: #fff;
      }
      &.medium-blue {
        background: $medium-blue !important;
        color: #fff;
      }

      &.light-blue {
        background: $light-blue !important;
        color: #fff;
      }

      &.teal {
        background: $teal !important;
        color: #fff;
      }

      &.olive {
        background: $olive !important;
        color: #fff;
      }
    }
  }
}
