.patient-flow-block {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.loaded {
    opacity: 1 !important;
  }
}

.patient-sbs-wrapper {
  margin-bottom: 40px;

  &__top {
    margin-top: 21px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $dark-blue;
      border-radius: 10px;
      text-transform: uppercase;
      z-index: 9;
      position: relative;

      @include breakpoint(medium) {
        font-size: 30px;
        width: 370px;
        height: 60px;
      }

      @include breakpoint(large) {
        margin: 0 auto;
      }
    }
  }

  &__step {
    display: flex;
    position: relative;
    padding-top: 20px;
    flex-direction: row;

    @include breakpoint(large) {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    @include breakpoint(medium) {
      padding-top: 5px;
    }

    &.is-first {
      padding-top: 30px;
      @include breakpoint(medium) {
        padding-top: 60px;
      }
    }
    &:nth-child(2n) {
      flex-direction: row;
      @include breakpoint(large) {
        justify-content: flex-end;

        .patient-sbs-wrapper__step__inner {
          order: 2;

          &:before {
            content: " ";
            width: 30px;
            height: 30px;
            background: #f3f5f8;
            position: absolute;
            left: -15px;
            z-index: 1;
            transform: rotate(45deg);
            box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.5);
          }

          img {
            order: 1;
          }
        }
      }
    }

    &__inner {
      position: relative;
      display: flex;
      flex-direction: row;
      // align-items: center;
      justify-content: flex-start;
      order: 2;
      min-height: 100px;
      padding: 20px;
      background: $gray;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      width: 100%;
      border-radius: 10px;
      transform: scale(0);
      transition: transform 0.4s ease 0.9s;

      .is-active & {
        transform: scale(1);
      }

      &:before {
        content: " ";
        width: 15px;
        height: 15px;
        background: #f3f5f8;
        position: absolute;
        left: -7.5px;
        z-index: 1;
        transform: rotate(45deg);
        box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.5);

        @include breakpoint(medium) {
          width: 30px;
          height: 30px;
          left: -15px;
        }

        @include breakpoint(large) {
          box-shadow: inset -1px 0px 0px 0px rgba(0, 0, 0, 0.5);
          right: -15px;
          left: auto;
        }
      }

      @include breakpoint(medium) {
        padding: 40px;
        width: 520px;
        min-height: 160px;
      }

      @include breakpoint(large) {
        order: 0;
      }

      .icon-wrapper {
        flex-grow: 1;
        min-width: 60px;

        @include breakpoint(medium) {
          flex-basis: 30%;
          height: 100px;
          width: 100px;
          min-width: 100px;
        }

        &__icon {
          border-radius: 50%;
          background: $dark-blue;
          height: 50px;
          width: 50px;
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: center;
          @include breakpoint(medium) {
            height: 100px;
            width: 100px;
            background-size: 55px;
          }
        }
      }

      img {
        width: 50px;
        height: 50px;

        @include breakpoint(medium) {
          width: 70px;
          height: 70px;
        }
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;

        h4,
        p {
          color: $dark-blue;
          font-size: 18px;
          font-weight: bold;
          flex: 1 1 auto;
          justify-content: flex-start;
          display: flex;
          margin-bottom: 0;
          margin-left: 30px;
          word-break: break-word;
          line-height: 1.2;

          @include breakpoint(medium) {
            font-size: 24px;
          }
        }

        &__expandable {
          color: $text-dblue !important;
          font-size: 16px !important;
          font-weight: normal !important;
          margin-top: 10px;
          margin-left: 30px;

          p {
            margin-left: 0;
            font-size: 16px !important;
            @include breakpoint(medium) {
              font-size: 18px !important;
            }
          }

          @include breakpoint(medium) {
            font-size: 18px !important;
          }
        }
        &__video-wrapper {
          position: relative;
          padding-bottom: 56.25%;
          padding-top: 25px;
          height: 0;
          margin-top: 1rem;
          margin-left: 0 !important;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &__number {
      transform: scale(0);
      transition: transform 0.4s ease 0.5s;
      position: relative;
      z-index: 1;

      padding-top: 17px;
      @include breakpoint(medium) {
        padding-top: 27px;
      }

      // .has-video & {
      //   padding-top: 17px;
      //   @include breakpoint(medium) { padding-top: 26px; }
      // }

      .is-active & {
        transform: scale(1);
      }

      h3 {
        width: 25px;
        height: 25px;
        margin: 0 20px;
        background: $light-blue;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 1;
        z-index: 9;
        position: relative;
        word-break: break-word;

        @include breakpoint(medium) {
          width: 60px;
          height: 60px;
          margin: 0 36px;
          font-size: 28px;
        }
      }
    }

    &__divider {
      width: 2px;
      height: 100%;
      left: 31px;
      position: absolute;
      bottom: 30px;
      /*bg*/
      background: repeating-linear-gradient(
        45deg,
        #cfd8e6,
        rgba(0, 0, 0, 0) 5px
      );

      @include breakpoint(medium) {
        bottom: 60px;
        left: 64px;
      }

      @include breakpoint(large) {
        left: 50%;
      }
    }

    &.has-video {
      .has-video__text-wrapper {
        @include breakpoint(medium) {
          margin-top: 1rem;
        }
      }
      .patient-sbs-wrapper__step__inner {
        flex-direction: column;

        &__content {
          flex-direction: row;
        }
      }
    }
  }
}
