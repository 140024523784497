.medium-blue {
  background: linear-gradient(
    to bottom right,
    $medium-blue,
    #005068
  ) !important;

  &.inverted {
    background: linear-gradient(to top right, $medium-blue, #005068) !important;
  }
  .contact-card ._block {
    background-color: transparent;
  }
  //contact-widget
  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: white;
    }
  }
  .contact-card._block {
    background-color: transparent;
    p,
    a,
    i {
      color: $medium-blue;
    }
  }
}

.cat-medium-blue {
  q {
    color: $medium-blue;
  }
  /* text overlay on hero */
  .landing-page-wrapper__hero__text-wrapper__inner {
    background: rgba($medium-blue, 0.9);

    p {
      color: #fff;
    }
  }
  /* Breadcrumb */
  .breadcrumb-wrapper {
    @include breakpoint(medium) {
      background: linear-gradient(
        to right,
        ($medium-blue, rgba(0, 80, 104, 0.9))
      ) !important;

      .breadcrumb-wrapper__inner-desktop__breadcrumb {
        color: #fff;

        &:hover,
        &:focus {
          border-color: #fff;
        }
      }
    }

    .breadcrumb-wrapper__inner-desktop__home {
      color: #fff;
    }
  }
  /* Border */
  .tilted-border {
    background: $medium-blue;

    &:before {
      background: $medium-blue;
    }

    &:after {
      background: $medium-blue;
    }
  }
  .side-menu--top {
    background: $medium-blue;
  }
}
