/* Overall  */

body {
  margin: 0;

  &:before {
    visibility: hidden;
    top: -9999px;
    position: fixed;

    @include breakpoint(small) {
      content: "small";
    }

    @include breakpoint(medium) {
      content: "medium";
    }

    @include breakpoint(large) {
      content: "large";
    }
    @include breakpoint(desktop) {
      content: "desktop";
    }

    @include breakpoint(xlarge) {
      content: "xlarge";
    }

    @include breakpoint(xxlarge) {
      content: "xxlarge";
    }
  }
}
img {
  width: unset !important;
  height: auto !important;
  max-width: 100%;
}

a,
button,
input[type="button"] {
  text-decoration: none !important;

  &:focus {
    outline-style: dashed;
    outline-width: 2px;
    outline-color: rgb(81, 81, 81);
  }
}

button {
  text-align: center;
}

.quote-block {
  font-size: 32px;
  line-height: 38px;
  color: #0e3c7c;
  margin: 30px 0;
  &:before {
    margin-right: 5px;
    content: open-quote;
  }
  &:after {
    margin-left: 5px;
    content: close-quote;
  }
}

q {
  @include breakpoint(medium) {
    margin: 30px 0;
    margin: 30px 0 30px 14px;
  }
  // margin-left: 0px;
  // text-indent: -14px;
  quotes: "“" "”" "‘" "’";
  font-size: 24px;
  margin: 20px 0 20px 14px;
  line-height: 30px;
  color: #0e3c7c;
  font-weight: 500;
  font-style: italic;
  display: block;
  text-indent: -25px;
  &:before,
  &:after {
    vertical-align: -14px;
    font-size: 50px;
    line-height: 0px;
  }
  &:before {
    margin-right: 5px;
    content: open-quote;
  }
  &:after {
    margin-left: 5px;
    content: close-quote;
  }
}
main {
  margin: 0 auto;
  padding-top: 80px;
  transition: 0.2s ease all;

  @include breakpoint(medium) {
    padding-top: 0;
    margin-top: 95px;
    display: block;
  }

  &.startpage {
    padding-top: 0;
    margin-top: 80px;

    @include breakpoint(medium) {
      margin-top: 0;
    }
  }

  &:focus {
    outline: none;
  }
}

.grid-1280 {
  width: 100%;
  padding: 0 15px;

  @include breakpoint(medium) {
    padding: 0 60px;
  }

  @include breakpoint(large) {
    width: 1170px;
    margin: 0 auto;
    padding: 0;
  }
}

.grid-930 {
  width: 100%;
  padding: 0 15px;

  @include breakpoint(medium) {
    padding: 0 60px;
  }

  @include breakpoint(large) {
    width: 930px;
    margin: 0 auto;
    padding: 0;
  }
}

.grid-780 {
  width: 100%;
  padding: 0 15px;

  @include breakpoint(medium) {
    padding: 0 60px;
  }

  @include breakpoint(large) {
    width: 780px;
    margin: 0 auto;
    padding: 0;
  }
}

.parallax {
  @include breakpoint(medium) {
    background-attachment: fixed !important;
  }
}
.content-editorial {
  word-break: break-word;
  & p {
    font-size: 19px;
  }
  a {
    border-bottom: 1px solid transparent;
    transition: 0.2s ease all;
    &:hover {
      transition: 0.2s ease all;
      border-color: inherit;
    }
  }
  @media print, screen and (max-width: 640px) {
    img {
      width: 100% !important;
    }
  }
}
/* Tilted border*/
.tilted-border {
  width: 230px;
  height: 6px;
  position: relative;
  background: $light-blue;
  margin: 5px 0 40px 5px;

  &:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 5px;
    z-index: 5;
    background: $light-blue;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
  }

  &:after {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 5px;
    z-index: 5;
    background: $light-blue;
    transform-origin: bottom left;
    -ms-transform: skew(-30deg, 0deg);
    -webkit-transform: skew(-30deg, 0deg);
    transform: skew(-30deg, 0deg);
  }
}

.white-border {
  background: #fff;

  &:before {
    background: #fff;
  }

  &:after {
    background: #fff;
  }
}

.no-scroll {
  overflow: hidden;

  @include breakpoint(desktop) {
    overflow: visible;
  }
}

.c-edit-mode {
  display: flex;
  align-items: center;
  flex-direction: column;
}
a[href$=".pdf"] {
  &:before {
    content: "\f1c1";
    font-family: "fontello";
    margin-right: 8px;
    color: inherit;
  }
}
@mixin standard-link {
  color: #0e3c7c;
  transition: 0.3s ease all;
  border-bottom: 1px solid transparent;
  &:hover {
    border-color: #0e3c7c;
  }
}
