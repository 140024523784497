.text-block {
  padding: 40px 0;
  background: #fff;

  @include breakpoint(medium) {
    padding: 55px 40px 55px 20px;
  }

  &.white + &.white {
    padding-top: 0;
  }
}

.image-block {
  width: 100%;
  flex: 1 1 50% !important;

  @include breakpoint(large) {
    width: 50%;
  }
}

/* Styling with CTA */
.with-cta {
  .text-block-wrapper {
    &__item {
      flex-direction: column;

      &__text-wrapper {
        align-self: flex-start;
      }
    }

    .text-block-wrapper__CTA {
      width: 100%;
    }
  }
}

/* Styling for dark background */
.dark-blue {
  .text-block-wrapper {
    &__item {
      &__icon-wrapper {
        color: #fff;
      }

      &__text-wrapper {
        &__heading {
          color: #fff;
        }

        &__text,
        &__preamble,
        p {
          color: #fff;
        }
      }
    }

    .text-block-wrapper__CTA {
      border-color: #fff;

      &__link {
        &__text {
          color: #fff;
        }

        &__icon {
          // color: #fff;
          border-color: #fff;
        }
      }
    }

    &__top {
      &__heading {
        color: #fff;
      }

      &__ingress {
        color: #fff;
      }
    }
  }
}

/* Styling for all type of columns */
.text-block-wrapper {
  @include breakpoint(medium) {
    padding-right: 0;
  }

  &__top {
    max-width: 780px;
    margin-left: 0;
    @include breakpoint(medium) {
      margin-left: -20px;
    }
    @include breakpoint(large) {
      margin-left: 0;
    }

    &__heading {
      text-align: left;
      @include breakpoint(medium) {
        font-size: 35px;
      }
    }

    &__ingress {
      display: inline-block;
      padding: 30px 0px;
      display: flex;
      justify-content: left;

      @include breakpoint(medium) {
        padding: 40px 0px;
      }
    }
  }

  &__margin-correction {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;

    @include breakpoint(medium) {
      margin-left: -20px;
    }

    @include breakpoint(large) {
      margin-left: -65px;
      flex-wrap: nowrap;
    }
  }

  &__heading,
  &__ingress,
  &__preamble,
  &__text {
    padding: 0;
    margin-bottom: 10px;
  }

  .tilted-border {
    margin-top: 30px;
    margin-bottom: 0;
  }

  &__CTA {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $dark-blue;
    padding-top: 30px;
    margin-top: 20px;

    @include breakpoint(medium) {
      margin-left: -20px;
    }
    @include breakpoint(large) {
      justify-content: flex-end;
    }

    &__link {
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        .text-block-wrapper__CTA__link__icon {
          color: #fff;
          border-color: $dark-blue;
          background: $dark-blue;
        }

        .text-block-wrapper__CTA__link__text {
          &:after {
            opacity: 1;
          }
          // color: $light-blue;
        }
      }

      &__text {
        color: $dark-blue;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        font-family: "Source Sans Pro", sans-serif;
        transition: 0.3s ease all;
        &:after {
          content: "";
          width: 0px;
          height: 2px;
          display: block;
          background: $dark-blue;
          width: 100%;
          opacity: 0;
          transition: 0.3s ease all;
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $dark-blue;
        color: $dark-blue;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        text-align: center;
        transition: 0.3s ease all;
        margin-left: 15px;
        font-size: 24px;

        &:before {
          margin-left: 0.3em;
        }
      }
    }
  }

  &__item {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 780px;
    align-items: flex-start;

    @include breakpoint(medium) {
      flex: 1 1 calc(33% - 20px);
      margin-right: 20px;
      align-items: center;
    }

    @include breakpoint(large) {
      align-items: flex-start;
      margin: 30px 0 30px 65px;
      flex-direction: row;
    }

    .tb-item-inner {
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      @include breakpoint(medium) {
        flex-direction: column;
      }
      @include breakpoint(desktop) {
        flex-direction: row;
      }
    }

    &__icon-wrapper {
      color: #fff;
      margin: 0 15px 30px 0;

      @include breakpoint(large) {
        margin: 0 30px 0 0;
      }

      &__icon {
        width: 80px !important;
      }

      i {
        font-size: 40px;
      }
    }

    &__text-wrapper {
      &__heading {
        font-size: 24px;
        @include breakpoint(medium) {
          font-size: 27px;
        }
      }
      flex-basis: 70%;
      flex-grow: 1;
      width: 100%;

      @include breakpoint(medium) {
        margin-left: 0;
      }

      &__heading,
      &__ingress,
      &__preamble,
      &__text {
        padding: 0;
        margin-bottom: 15px;
      }

      &__button {
        align-self: center;
        @include breakpoint(medium) {
          align-self: flex-start;
        }
        display: inline-block;
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        background: $light-blue;
        padding: 10px 30px;
        border-radius: 30px;
        margin-top: 20px;
        transition: 0.3s ease all;

        &:hover,
        &:focus {
          background: $dark-blue;
          color: #fff;
        }
      }
    }
  }
  /* Styling for four columns */
  &__margin-correction.four {
    flex-wrap: wrap;

    .text-block-wrapper__item {
      display: flex;
      align-items: baseline;
      flex: 0 1 auto;
      // margin: 20px 0px 20px 20px;
      @include breakpoint(medium) {
        width: calc(50% - 20px);
      }

      @include breakpoint(large) {
        width: calc(50% - 65px);
        margin: 20px 0px 20px 65px;
      }

      &__text-wrapper {
      }

      &__icon-wrapper {
        i {
          font-size: 40px;
          margin-right: 20px;
          color: #fff;
        }
      }
    }
  }
}
/* 5 or more items display next to each other */
.text-block-wrapper__item:first-child:nth-last-child(n + 3),
.text-block-wrapper__item:first-child:nth-last-child(n + 3)
  ~ .text-block-wrapper__item {
  .text-block-wrapper__item__text-wrapper__heading {
    font-size: 24px;
    @include breakpoint(medium) {
      font-size: 27px;
    }
  }
}
