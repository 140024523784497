.news-archive-wrapper {
  .sf-wrapper {
  }
  .search-result-wrapper {
    background: #fff;
  }

  .sr-inner__result-box:nth-child(2n) {
    background: $gray;
  }
}

//not used
.specify-image-format-test-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 66.66%;
    overflow: hidden;
  }
  &__image {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s ease-in-out;
  }
}
