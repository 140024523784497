.dark-blue {
  &.inverted {
    background: linear-gradient(
      to top right,
      $light-blue,
      $dark-blue
    ) !important;
  }

  background: linear-gradient(
    to bottom right,
    $light-blue,
    $dark-blue
  ) !important;

  .contact-card ._block {
    background-color: transparent;
  }
  .calltoaction-block-inner-wrapper__button {
    background: white;
    color: $light-blue;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .quote-block-inner-wrapper__text-wrapper__button {
    background: white;
    color: $light-blue;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .text-block-wrapper__item__text-wrapper__button {
    background: white;
    color: $light-blue;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .image-link-wrapper__button {
    background: white;
    color: $light-blue;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff !important;
    }
  }
  //contact-widget
  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: white;
    }
  }
  .contact-card._block {
    background-color: transparent;
    p,
    a,
    i {
      color: $dark-blue;
    }
  }
  .text-block-wrapper__CTA__link {
  }

  /*cta button*/
  .quote-block-inner-wrapper__text-wrapper__button {
    background: #fff;
    color: $light-blue;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $light-blue;
      color: #fff;
    }
  }
}

.cat-dark-blue {
  /* text overlay on hero */
  .landing-page-wrapper__hero__text-wrapper__inner {
    background: rgba($dark-blue, 0.9);

    p {
      color: #fff;
    }
  }
  /* Breadcrumb */
  .breadcrumb-wrapper {
    @include breakpoint(medium) {
      background: linear-gradient(
        to right,
        $dark-blue,
        rgba(14, 60, 124, 0.75)
      );

      .breadcrumb-wrapper__inner-desktop__breadcrumb {
        color: #fff;

        &:hover,
        &:focus {
          border-color: #fff;
        }
      }
    }

    .breadcrumb-wrapper__inner-desktop__home {
      color: #fff;
    }
  }
  /* Border */
  .tilted-border {
    background: $dark-blue;

    &:before {
      background: $dark-blue;
    }

    &:after {
      background: $dark-blue;
    }
  }
  .side-menu--top {
    background: $dark-blue;
  }
}
