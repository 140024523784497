.saf-wrapper {
  width: 100%;
  padding: 40px 0;

  @include breakpoint(medium) {
    padding: 60px 0;
    background: linear-gradient(
      to bottom right,
      rgba($light-blue, 0.9) 0%,
      rgba($dark-blue, 0.9) 50%
    );
  }
}

.saf-wrapper-inner {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  @include breakpoint(medium) {
    padding: 0;
    width: 600px;
  }

  h1 {
    @include breakpoint(medium) {
      color: #fff;
    }
  }

  &__search {
    margin-bottom: 30px;

    &__input {
      position: relative;

      &__field {
        font-family: "Source Sans Pro", sans-serif;
        padding: 0 25px 0 20px;
        font-size: 24px;
        margin: 20px 0;
        height: 60px;
        border: 1px solid #979797;
        border-radius: 30px;

        @include breakpoint(medium) {
          border-radius: 5px;
        }

        &:focus {
          background: $cream;
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &::-ms-clear {
          display: none;
        }
      }

      &__icon {
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 30px;
        color: $dark-blue;
        cursor: pointer;
        transition: 0.2s ease all;

        &:hover,
        &:focus {
          color: #0d97f2;
        }
      }
    }

    &__results {
      text-align: center;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 20px;
      color: $dark-blue;
      opacity: 1;
      transition: opacity 0.3s ease;

      @include breakpoint(medium) {
        color: #fff;
      }

      &.is-loading {
        opacity: 0;
      }

      &__amount,
      &__word {
        font-weight: bold;
      }

      &__amount {
        margin-right: 4px;
      }
    }
  }
}

.filterbutton-wrapper {
  background: $mystic;
  min-height: 0;
  padding: 0;
  transition: all 0.3s ease;

  &.is-active {
    padding: 20px 0 15px 0;
    min-height: 131px;
  }
}

.filterbutton-wrapper-inner {
  &__margin-correction {
    margin-left: -45px;
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
      flex-direction: row;
    }
  }

  &__filter {
    margin-left: 30px;
    flex: 0 1 50%;
    opacity: 0;
    transition: all 0.3s ease;

    &.fade-in {
      opacity: 1;
    }

    &__heading {
      font-family: "Source Sans Pro", sans-serif;
      color: $dark-gray;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
      margin-left: 15px;
    }

    &__btn-wrapper {
      display: flex;
      flex-wrap: wrap;

      &__btn {
        background: #fff;
        padding: 12px 25px;
        border-radius: 30px;
        color: $dark-gray;
        cursor: pointer;
        transition: 0.2s ease all;
        margin-left: 15px;
        box-shadow: 0 1px 0 0 #9fb1cb;
        margin-bottom: 10px;

        &:focus,
        &:hover,
        &.active {
          background: $light-blue;
          color: #fff;
        }
      }
    }
  }
}
