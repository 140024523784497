.image-link-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 100%;
    background-image: url("https://via.placeholder.com/588x290");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;

    @include breakpoint(medium) {
      height: 162px;
    }
    @include breakpoint(large) {
      height: 200px;

      .is-two & {
        height: 275px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 180px;
    padding: 10px 30px;
    height: 40px;
    bottom: 20px;
    position: relative;
    background: $light-blue;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 30px;
    text-align: center;
    color: #fff;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    // white-space: nowrap;
    cursor: pointer;
    transition: 0.2s ease all;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    @include breakpoint(small) {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    @include breakpoint(large) {
      font-size: 16px;
    }

    &:hover,
    &:focus {
      background: $dark-blue;
      color: #fff;
    }
  }
}
