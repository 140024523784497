.olive {
  background: linear-gradient(to bottom right, $olive, #487314) !important;
  &.inverted {
    background: linear-gradient(to top right, $olive, #487314) !important;
  }

  .c-image-links-block__wrapper__item__text-wrapper__ingress,
  .c-image-links-block__ingress {
    a {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }

  .quote-block-inner-wrapper__text-wrapper__CTA__link:hover
    .quote-block-inner-wrapper__text-wrapper__CTA__link__icon,
  .quote-block-inner-wrapper__text-wrapper__CTA__link:focus
    .quote-block-inner-wrapper__text-wrapper__CTA__link__icon {
    color: #000;
    background: #fff;
    border-color: #fff;
  }

  .quote-block-inner-wrapper__text-wrapper__CTA__link:hover
    .quote-block-inner-wrapper__text-wrapper__CTA__link__text,
  .quote-block-inner-wrapper__text-wrapper__CTA__link:focus
    .quote-block-inner-wrapper__text-wrapper__CTA__link__text {
    color: #fff;
  }

  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: white;
    }
  }
  .contact-card ._block {
    background-color: transparent;
    p {
      color: $dark-blue;
    }
  }
}
/* Color category */
.cat-olive {
  /* text overlay on hero */
  .landing-page-wrapper__hero__text-wrapper__inner {
    background: rgba($olive, 0.9);

    p {
      color: #fff;
    }
  }

  q {
    color: $olive;
  }
  /* Breadcrumb */
  .breadcrumb-wrapper {
    @include breakpoint(medium) {
      background: linear-gradient(to right, $olive, rgba(99, 151, 48, 0.75));

      .breadcrumb-wrapper__inner-desktop__breadcrumb {
        color: #fff;

        &:hover,
        &:focus {
          border-color: #fff;
        }
      }
    }

    .breadcrumb-wrapper__inner-desktop__home {
      color: #fff;
    }
  }
  /* Border */
  .tilted-border {
    background: $olive;

    &:before {
      background: $olive;
    }

    &:after {
      background: $olive;
    }
  }
  .side-menu--top {
    background: $olive;
  }
}
