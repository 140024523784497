.breadcrumb-wrapper {
  padding: 8px 0;

  @include breakpoint(medium) {
    background: $gray;
  }

  &__inner-desktop {
    display: none;

    @include breakpoint(medium) {
      display: flex;
    }

    &__home {
      font-size: 16px;
      color: $dark-blue;
    }

    &__breadcrumb {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: #575757;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      transition: 0.2s ease all;
      margin-left: 30px;
      position: relative;
      font-weight: bold;

      &.current {
        font-weight: normal;

        &:hover,
        &:focus {
          border-color: rgba(0, 0, 0, 0) !important;
        }
      }

      &:hover {
        &:not(.current) {
          border-color: $light-blue;
        }
      }

      &:before {
        font-family: "fontello";
        content: "\e800";
        margin: 0 10px;
        border-bottom: 0;
        position: absolute;
        left: -29px;
      }
    }
  }

  &__inner-phone {
    display: inline-block;
    padding-top: 10px;
    @include breakpoint(medium) {
      display: none;
    }

    &__breadcrumb {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      color: #575757;
      display: inline-block;
      text-decoration: underline !important;

      @include breakpoint(medium) {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none !important;
      }

      &:before {
        font-family: "fontello";
        content: "\e802";
        margin-right: 10px;
        display: inline-block;
        text-decoration: none !important;
      }
    }
  }
}
