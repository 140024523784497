/* Fonts */

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap&subset=latin-ext");

/* Text styling*/
h1,
h2,
h3,
h4 {
  font-family: "Source Sans Pro", sans-serif;
  color: $dark-blue;
  font-weight: bold;
  word-break: break-word;
  margin: 0;
  line-height: 1.2;
}

h1 {
  font-size: 38px;

  @include breakpoint(large) {
    font-size: 48px;
  }
}

h2 {
  font-size: 30px;

  @include breakpoint(large) {
    font-size: 35px;
  }
}

h3 {
  font-size: 24px;

  @include breakpoint(large) {
    font-size: 27px;
  }
}

h4 {
  font-size: 18px;

  @include breakpoint(large) {
    font-size: 22px;
  }
}
.content-editorial {
  max-width: 780px;
  font-size: 19px;
}
.content-editorial,
.RTE-wrapper {
  h2 {
    @include breakpoint(large) {
      font-size: 27px;
    }
    font-size: 24px;

    margin: 20px 0 10px 0;
  }
  h3 {
    font-size: 20px;
    @include breakpoint(large) {
      font-size: 24px;
    }
  }
  h4 {
    font-size: 18px;
    @include breakpoint(large) {
      font-size: 20px;
    }
  }
}

.content-editorial,
p {
  color: #252525;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.55;
  font-size: 19px;
  font-weight: normal;

  ul {
    color: #252525;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 1.55;
    font-size: 18px;
    font-weight: normal;
  }

  iframe,
  img {
    width: 100%;
  }
}

.ingress {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 22px;
  line-height: 1.17;
  word-break: break-word;

  @include breakpoint(large) {
    font-size: 24px;
  }
}

.ktc-admin-ui {
  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    font-family: "Core-icons" !important;
  }
}
.ktc-admin-ui ol,
.ktc-admin-ui ol li {
  list-style: decimal !important;
  list-style-type: decimal !important;
}
