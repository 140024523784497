.side-menu {
  @include breakpoint(medium) {
    margin-top: 15px;
  }
  margin-top: 25px;

  max-width: 250px;
  min-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  &--top {
    padding: 1rem;
    background: $dark-blue;
    display: flex;
    align-items: center;
    h2 {
      border-bottom: 2px solid transparent;
      transition: 0.2s ease all;
    }

    &:hover {
      h2 {
        border-color: white;
        transition: 0.2s ease all;
      }
    }
    &:hover {
      h4 {
        border-color: white;
      }
    }
    h2 {
      color: white;
      font-size: 19px;
      margin: 0;
    }
  }
  &--bottom {
    padding: 0.5rem 1rem;
    background-color: #f3f5f8;
    display: flex;
    flex-direction: column;
    &__item {
      border-bottom: 1px solid #808080;

      display: flex;
      margin: 5px 0;
      &:hover {
        span {
          transition: 0.3s ease all;
          border-color: #575757;
        }
      }
      &__span {
        border-bottom: 2px solid transparent;
        width: fit-content;
        transition: 0.3s ease all;

        margin-bottom: 10px;
        color: #575757;
      }
    }
    &__item:last-of-type {
      border-bottom: none;
    }
  }
}
