.main-course {
  margin-top: 0;
}

.course-page-wrapper {
  //hero

  &__hero {
    display: flex;

    @include breakpoint(medium) {
      // min-height: 400px;
      height: 400px;
    }

    &__left {
      display: flex;
      width: 40%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: none;
      @include breakpoint(medium) {
        display: flex;
      }
      img {
        width: 100%;
      }
    }
    &__right {
      background: #008c7e;
      justify-content: center;
      display: flex;
      width: 100%;
      flex-direction: column;
      @include breakpoint(medium) {
        width: 60%;
      }
      &__text {
        padding: 20px 15px;

        @include breakpoint(medium) {
          margin-left: 65px;
          width: calc(100% - 65px);
          padding: 0;
        }

        @include breakpoint(large) {
          width: 715px;
        }
        &__button {
          .icon-link-ext {
            color: $teal;
          }
          display: inline-block;
          font-family: "Source Sans Pro", sans-serif;
          text-transform: uppercase;
          font-size: 18px;
          color: $teal;
          font-weight: bold;
          background: #fff;
          padding: 5px 40px;
          border-radius: 30px;
          margin-top: 40px;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            .icon-link-ext {
              transition: 0.2s ease all;

              color: #fff;
            }
            background: $light-blue;
            color: #fff;
          }
        }

        &__cat {
          font-family: "Source Sans Pro", sans-serif;
          color: #fff;
          margin-bottom: 0;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }

        &__heading {
          font-size: 34px !important;
          line-height: 1.2 !important;
          color: #fff;
          margin-top: 40px;
          margin-bottom: 30px;
          word-break: break-word;

          @include breakpoint(medium) {
            font-size: 38px !important;
          }
        }

        &__row {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;

          i {
            color: #fff;
            margin-right: 5px;
          }

          p {
            font-family: "Source Sans Pro", sans-serif;
            color: #fff;
            margin-bottom: 0;

            span {
              font-weight: bold;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  // occasions

  &__inner {
    margin: 50px auto;

    .list-wrapper {
      background-color: #f3f5f8;
    }

    &__ingress {
      max-width: 1000px;
      strong {
        color: #0e3c7c;
      }
      h3 {
        margin-bottom: 10px;
      }
    }

    &__list {
      width: 70%;
      display: flex;
      flex-direction: column;
      &__info-wrapper {
        justify-content: space-between;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 20px;
        @include breakpoint(medium) {
          padding: 0 20px;
        }
        &__item {
          display: flex;
          align-items: center;
          margin-right: 40px;
          i {
            color: $light-blue;
            font-size: 20px;
          }
          &__text {
            font-family: "Source Sans Pro", sans-serif;
            display: inline-block;
            transition: 0.2s ease all;
            //  color: #575757;
            color: #252525;
            font-weight: 600;

            padding: 5px;
          }
        }
      }
    }

    &__button-wrapper {
      display: flex;
      padding-bottom: 25px;

      &__button {
        background: $light-blue;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        font-family: "Source Sans Pro", sans-serif;
        display: block;
        max-width: 200px;
        padding: 10px 20px;
        margin-top: 15px;
        border-radius: 30px;
        transition: 0.2s ease all;
        box-shadow: 0 1px 0 0 #9fb1cb;

        &:focus,
        &:hover {
          background: $dark-blue;
          color: #fff;
        }
      }
    }

    .ingress {
      font-size: 24px !important;

      @include breakpoint(medium) {
        font-size: 28px !important;
      }
    }

    h2,
    h3 {
      font-size: 28px;

      @include breakpoint(medium) {
        font-size: 30px;
      }
    }

    p {
      margin: 2px 0;
      display: block;
    }

    ul {
      margin: 10px 0px 10px 25px;
      color: #252525;
      font-family: "Source Sans Pro", sans-serif;
      line-height: 1.55;
      font-size: 18px;
      font-weight: normal;

      li {
        margin: 5px 0;

        &:before {
          content: "\e80b";
          font-family: fontello;
          margin-left: -25px;
          margin-right: 10px;
          color: $dark-blue;
        }
      }
    }

    &__contact {
      font-size: 24px !important;
      margin-top: 40px;
      line-height: 1.25;

      @include breakpoint(medium) {
        font-size: 24px !important;
      }
    }
    .course-list__inner__info-wrapper__bottom {
      margin-bottom: 10px;
    }
  }
}

// similar courses
.similar-courses-headline {
  margin-bottom: 15px;
  margin-top: 50px;
}
.similar-course-list {
  &__margin-correction {
    display: flex;

    @include breakpoint(medium) {
      margin-left: -30px;
      align-items: stretch;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include breakpoint(medium) {
      flex-direction: row;
    }

    &__column {
      width: 100%;
      height: 100%;

      @include breakpoint(medium) {
        width: 33%;
        margin-left: 30px;
        height: calc(100% - 60px);
      }

      .course-plug {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        display: inline-block;
        margin-top: 20px;
        width: 100%;

        @include breakpoint(medium) {
          height: calc(100% + 30px);
          margin-top: 0;
        }

        &:hover,
        &:focus {
          .course-plug__image {
            opacity: 0.8;
          }

          .course-plug__heading {
            color: $light-blue;
          }
        }

        &__image {
          width: 100%;
          transition: 0.2s ease all;
          height: 225px;
        }

        &__date {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: baseline;
          padding: 5px 25px;
          background: $gray;
          min-height: 45px;

          p {
            font-family: "Source Sans Pro", sans-serif;
            margin: 0;
            font-size: 14px;
            min-width: 30%;
            line-height: 2;

            &:before {
              font-family: "fontello";
              content: "\f133";
              margin-right: 8px;
              font-weight: bold;
              color: $dark-blue;
            }
          }
        }

        &__heading {
          padding: 25px 25px 10px 25px;
          font-size: 20px !important;
          transition: 0.2s ease all;
        }

        &__text {
          padding: 0 25px 25px 25px;
          color: black;
        }
      }

      &__more-btn {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding-top: 30px;
        border-top: 1px solid #979797;
        width: 100%;

        &:hover,
        &:focus {
          .similar-course-list__inner__column__more-btn__icon {
            color: #fff;
            border-color: $light-blue;
            background: $light-blue;
          }

          .similar-course-list__inner__column__more-btn__text {
            color: $light-blue;
          }
        }

        &__text {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 18px;
          text-transform: uppercase;
          color: $dark-blue;
          font-weight: bold;
          transition: 0.3s ease all;
        }

        &__icon {
          font-size: 24px;
          padding-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $dark-blue;
          color: $dark-blue;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          transition: 0.3s ease all;
          margin-left: 15px;
        }
      }
    }
  }
}
