/*Colors*/
$text-dblue: #090623;
$dark-blue: #0e3c7c;
$button-blue: #0b2c5b;
$medium-blue: #19809f;
$light-blue: #017acb;
$teal: #008c7e;
$olive: #639730;
$gray: #f3f5f8;
$dark-gray: #575757;
$deep-gray: #282625;
$cream: #fcfbec;
$mystic: #e6ebf1;
$boticelli: #cfd8e5;

// Applies to all dark colors

.teal,
.dark-blue,
.medium-blue,
// .dark-blue,
.olive {
  a:focus,
  button:focus,
  input[type="button"]:focus {
    outline-style: dashed;
    outline-width: 2px;
    outline-color: #fff;
  }
  .c-image-links-block__wrapper__item__text-wrapper__ingress,
  .c-image-links-block__ingress {
    a {
      color: #fff;
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: white;
      }
    }
  }
  .quote-block-inner-wrapper__text-wrapper__heading {
    color: white;
  }
  .text-block-wrapper {
    &__CTA {
      &__link {
        &:hover {
          .text-block-wrapper__CTA__link__icon {
            color: $dark-blue;

            border-color: #fff;
            background: white;
          }
          &__text:after {
            opacity: 1;
          }
        }
        &__icon {
          color: #fff;

          border-color: #fff;
          background: transparent;
        }
        &__text {
          &:after {
            content: "";
            width: 0px;
            height: 2px;
            display: block;
            background: #fff;
            width: 100%;
            opacity: 0;
            transition: 0.3s ease all;
          }
        }
      }
    }
  }

  //calltoaction
  .text-block-wrapper__CTA__link__text:after {
    background: #fff;
  }
  .side-menu--top h4 {
    color: #fff;
  }

  // editorial
  .content-editorial h1,
  .content-editorial h2,
  .content-editorial h3,
  .content-editorial h4,
  .content-editorial span,
  .content-editorial p {
    color: #fff;
  }

  .content-editorial a {
    color: #fff;
    border-bottom: 2px solid transparent;
    transition: 0.3s ease all;

    &:hover {
      transition: 0.3s ease all;

      border-color: #fff;
    }
  }
  .content-editorial a[href*="http"]:not([href*="pdf"])::after,
  .c-image-links-block__wrapper__item__text-wrapper__ingress
    a[href*="http"]:not([href*="pdf"])::after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    color: #fff;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    content: "\f08e";
    /* font-size: 120%; */
    -webkit-font-smoothing: antialiased;
  }
  ul {
    color: #fff !important;
  }

  /* Text color on text block */
  .text-block__heading,
  .text-block-wrapper__top__heading,
  .text-block-wrapper__item__text-wrapper__preamble,
  .text-block-wrapper__CTA__link__text,
  .text-block-wrapper__CTA__link,
  .text-block-wrapper__item__text-wrapper__heading,
  .text-block-wrapper__item__text-wrapper__text,
  .text-block-wrapper__top__ingress,
  .text-block-wrapper__item__text-wrapper__heading {
    color: #fff;
  }

  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: white;
    }
  }
  .contact-card._block {
    background-color: transparent;
    p,
    a,
    i {
      color: $dark-blue;
    }
  }

  .text-block-wrapper__CTA {
    border-color: #fff;

    &:hover,
    &:focus {
      .text-block-wrapper__CTA__link__text {
        color: #fff;
      }
    }
  }

  /*cta button -> different in _dark-blue.scss*/
}

//Buttons
.teal,
.medium-blue,
.olive {
  .calltoaction-block-inner-wrapper__button {
    background: $button-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: #fff;
      color: $button-blue;
    }
  }
  .quote-block-inner-wrapper__text-wrapper__button {
    background: $button-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: #fff;
      color: $light-blue;
    }
  }
  .text-block-wrapper__item__text-wrapper__button {
    background: $button-blue;
    color: #fff;

    &:hover,
    &:focus {
      background: #fff;
      color: $light-blue;
    }
  }
  .image-link-wrapper__button {
    background: $button-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: #fff;
      color: $light-blue;
    }
  }
}
.gray,
.cream,
.white {
  .calltoaction-block-inner-wrapper__button {
    background: $light-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .quote-block-inner-wrapper__text-wrapper__button {
    background: $light-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .text-block-wrapper__item__text-wrapper__button {
    background: $light-blue;
    color: #fff;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
  .image-link-wrapper__button {
    background: $light-blue;
    color: #fff;
    text-shadow: none;

    &:hover,
    &:focus {
      background: $button-blue;
      color: #fff;
    }
  }
}

// Applies to all light colors
