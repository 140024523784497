.sf-wrapper {
  width: 100%;
  padding: 40px 0;
  display: flex;
  height: 180px;
  align-items: center;

  @include breakpoint(medium) {
    height: 280px;
    padding: 60px 0;
    background: linear-gradient(to bottom right, rgba($light-blue,.9) 0%, rgba($dark-blue,.9) 50%) !important;
  }
}

.sf-wrapper-inner {



  h1 {
    color: $dark-blue;
    @include breakpoint(medium) {
      color: white;
    }
  }

  &__search {
    margin-bottom: 30px;
    width: 100%;

    @include breakpoint(medium) {
      padding: 0;
      width: 600px;
    }

    &__input {
      position: relative;

      &__field {
        font-family: 'Source Sans Pro', sans-serif;
        padding: 0 25px 0 20px;
        font-size: 24px;
        margin: 20px 0;
        height: 60px;
        border: 1px solid #979797;
        border-radius: 30px;

        @include breakpoint(medium) {
          border-radius: 5px;
        }

        &::placeholder {
          color: $dark-gray;
          font-size: 24px;
        }

        &:focus {
          background: $cream;
        }

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }

        &::-ms-clear {
          display: none;
        }
      }

      &__icon {
        position: absolute;
        top: 8px;
        right: 12px;
        font-size: 30px;
        color: $dark-blue;


        @include breakpoint(medium) {
          right: 8px;
        }
      }
    }

    &__results {
      text-align: center;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 20px;
      color: #fff;

      &__amount, &__word {
        font-weight: bold;
      }
    }
  }
}
