.search-result-wrapper {
  padding: 40px 0;
  background: $gray;
  min-height: 400px;

  &__loading {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(240, 248, 255, 0.439);
    display: flex;
    z-index: -1;
    transition: opacity 0.4s ease;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    &.is-active {
      opacity: 1;
      z-index: 7;
    }
  }
}

.sr-inner {
  form {
    width: 100%;
  }

  &__sort-row {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: $dark-gray;

    &__heading {
      margin-right: 20px;
    }

    &__option {
      color: $dark-gray;
      transition: 0.2s ease all;
      margin-right: 10px;

      &:focus,
      &:hover {
        text-decoration: underline !important;
        color: $dark-blue;
      }

      &.active {
        text-decoration: underline !important;
        font-weight: bold;
      }
    }
  }

  &__result-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-top: 20px;
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);

    &:hover,
    &:focus {
      .sr-inner__result-box__left__heading {
        color: $dark-blue;
      }

      .sr-inner__result-box__right__icon {
        background: $dark-blue;
      }
    }
    .news-archive-news-image {
      display: none;

      @include breakpoint(large) {
        display: inline-block;
        flex-basis: 33%;
      }
    }

    &__left {
      flex: 1 1 85%;
      padding: 15px 30px;

      @include breakpoint(medium) {
        flex: 1 1 70%;
        padding: 30px 0 30px 60px;
      }

      &__heading {
        color: $dark-blue;
        line-height: 1.3;
        font-size: 22px;
        @include breakpoint(medium) {
          font-size: 24px;
        }

        transition: 0.2s ease all;
      }

      &__text {
        margin: 15px 0;
      }

      &__tag-wrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      &__tag {
        font-family: "Source Sans Pro", sans-serif;
        padding: 5px 15px;
        font-size: 12px;
        font-weight: 600;
        display: inline-block;
        border-radius: 30px;
        margin: 2px;
        background: #f3f5f8;
        color: #0e3c7c;

        &.teal {
          background: $teal !important;
          color: #fff !important;
        }

        &.olive {
          background: $olive !important;
          color: #fff !important;
        }

        &.dark-blue {
          background: $dark-blue !important;
          color: #fff !important;
        }
        &.medium-blue {
          color: #fff !important;
        }
      }

      &__date {
        display: inline-flex;
        align-items: flex-end;
        color: $dark-gray;
        margin-left: 20px;
        font-weight: 600;

        i {
          color: $dark-blue;
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }

    &__right {
      width: 15%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;

      @include breakpoint(medium) {
        width: 20%;
        padding-right: 60px;
      }

      &__icon {
        width: 20px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        background: $light-blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease all;

        @include breakpoint(medium) {
          width: 35px;
          height: 35px;
          font-size: 18px;
        }
      }
    }
  }

  &__load-more-wrapper {
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translateY(2rem);
    transition: all 0.3s ease 1s;

    &.is-active {
      transform: translateY(0);
      opacity: 1;
    }

    &__load-more {
      font-family: "Source Sans Pro", sans-serif;
      text-transform: uppercase;
      font-size: 20px;
      color: $dark-blue;
      font-weight: bold;
      margin-top: 40px;
      cursor: pointer;
      transition: 0.2s ease all;

      &:focus,
      &:hover {
        .sr-inner__load-more-wrapper__load-more__icon {
          background: $dark-blue;
          color: #fff;
        }
      }

      &__icon {
        padding: 4px 3px;
        border: 2px solid $dark-blue;
        border-radius: 50%;
        margin-left: 15px;
        transition: 0.2s ease all;
      }
    }
  }
}
