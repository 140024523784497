//todo

.ktc-checkbox {
  display: flex;
  // justify-content: center;
  margin-right: 10px;
  align-items: flex-start;
  a {
    float: right;
  }
}
.form-control {
  margin: 6px 6px 0 0;
}
.formwidget-submit-text {
  max-width: 1170px;
  margin: 0 auto;
}
.aisab-form {
  max-width: 780px;
  form {
    padding: 20px;
    background: $gray;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  }

  .editing-form-control-nested-control {
    position: relative;
    top: 10px;
  }

  .control-label {
    color: $dark-blue;
    font-weight: bold;
    font-size: 20px;
    min-width: 250px;
    padding: 0 20px 0 0;
    margin-right: 20px;
  }

  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"],
  textarea {
    border-radius: 4px;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
  }

  select {
    border-radius: 4px 4px 0px 0px;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
  }

  .ktc-checkbox label {
    display: inline-block;
  }
}
div[id*="form-"] {
  margin: 40px auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1170px;

  @include breakpoint(medium) {
    padding: 0 60px;
    margin: 60px auto;
  }

  @include breakpoint(large) {
    margin: 0 auto;
    padding: 0;
  }

  .text-block:not(.image-overlay) + & {
    margin: -40px auto 40px auto;

    @include breakpoint(medium) {
      margin: -80px auto 80px auto;
    }
  }
}
div[id*="form-"] {
  margin: 1rem auto;

  @include breakpoint(medium) {
    margin: 3rem auto;
  }

  form {
    max-width: 600px;
  }
}
div[id*="form-"],
.aisab-form {
  [type="submit"] {
    width: 199px;
    height: 40px;
    border-radius: 27px;
    border: solid 3px $light-blue;
    background-color: $light-blue;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease all;
    margin-top: 20px;

    &:focus,
    &:hover {
      background: $dark-blue;
      color: #fff;
      border-color: $dark-blue;
    }
  }
}
