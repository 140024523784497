.teal {
  background: linear-gradient(
    to bottom right,
    $teal,
    #008577 /* #005448 */
  ) !important;

  &.inverted {
    background: linear-gradient(
      to top right,
      $teal,
      #008577 /* #005448 */
    ) !important;
  }

  .c-image-links-block__wrapper__item__text-wrapper__ingress,
  .c-image-links-block__ingress {
    a {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }

  .content-editorial h1,
  .content-editorial h2,
  .content-editorial h3,
  .content-editorial h4,
  .content-editorial span,
  .content-editorial p {
    color: #fff;
  }
  .content-editorial a {
    transition: 0.3s ease all;

    border-bottom: 2px solid transparent;
    &:hover {
      transition: 0.3s ease all;

      border-color: $white;
    }
  }

  .quote-block-inner-wrapper__text-wrapper__CTA__link:hover
    .quote-block-inner-wrapper__text-wrapper__CTA__link__icon,
  .quote-block-inner-wrapper__text-wrapper__CTA__link:focus
    .quote-block-inner-wrapper__text-wrapper__CTA__link__icon {
    color: #000;
    background: #fff;
    border-color: #fff;
  }

  .quote-block-inner-wrapper__text-wrapper__CTA__link:hover
    .quote-block-inner-wrapper__text-wrapper__CTA__link__text,
  .quote-block-inner-wrapper__text-wrapper__CTA__link:focus
    .quote-block-inner-wrapper__text-wrapper__CTA__link__text {
    color: #fff;
  }

  .contact-card ._block {
    background-color: transparent;
  }

  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: white;
    }
  }
}

.cat-teal {
  /* text overlay on hero */
  .landing-page-wrapper__hero__text-wrapper__inner {
    background: rgba($teal, 0.9);

    p {
      color: #fff;
    }
  }
  q {
    color: $teal;
  }
  /* Breadcrumb */
  .breadcrumb-wrapper {
    @include breakpoint(medium) {
      background: linear-gradient(to right, $teal, rgba(0, 140, 126, 0.75));

      .breadcrumb-wrapper__inner-desktop__breadcrumb {
        color: #fff;

        &:hover,
        &:focus {
          border-color: #fff;
        }
      }
    }

    .breadcrumb-wrapper__inner-desktop__home {
      color: #fff;
    }
  }
  /* Border */
  .tilted-border {
    background: $teal;

    &:before {
      background: $teal;
    }

    &:after {
      background: $teal;
    }
  }
  .side-menu--top {
    background: $teal;
  }
}
