.job-form {
  background-color: #f3f5f8;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: relative;

  &.is-active {
    opacity: 1;
  }

  &__loading {
    position: absolute;
    transition: opacity 0.2s ease;
    background: rgba(255, 255, 255, 0.83);
    width: 100%;
    opacity: 0;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -999;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-active {
      z-index: 999;
      opacity: 1;
    }
  }

  &__subscribe-block {
    transform: translateY(-9999rem);
    visibility: hidden;
    position: absolute;
    transition: transform 0.3s ease;

    input {
      visibility: hidden;
    }

    &.is-active {
      transform: translateY(-60px);
      visibility: visible;

      input {
        visibility: visible;
      }

      position: relative;
    }
  }

  .job-form-button {
    align-self: center;
    @include breakpoint(medium) {
      align-self: flex-end;
    }
    width: 100%;
    height: 50px;
    border-radius: 27px;
    border: solid 3px $light-blue;
    background-color: $light-blue;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease all;
    margin-top: 20px;

    &:focus,
    &:hover {
      background: $dark-blue;
      color: #fff;
      border-color: $dark-blue;
    }

    &--green {
      background-color: #649637;
      border-color: #649637;
      font-size: 16px;
      height: 40px;
      margin-top: 0;
      align-self: unset;

      &:focus,
      &:hover {
        background: darken(#649637, 5%);
        border-color: darken(#649637, 5%);
      }
    }
  }

  &__wrapper {
    padding: 40px 1rem;
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      padding: 100px 4rem;
    }
  }

  &__header {
    margin: 0 0 10px 0;
    max-width: 970px;
    font-size: 24px;

    @include breakpoint(desktop) {
      font-size: 30px;
    }

    @include breakpoint(large) {
      font-size: 32px;
    }
  }

  &__questions {
    margin: 50px 0;
    display: flex;

    &__question {
      transition: all 0.4s ease;
      transform: translate3d(100%, 0, 0);
      opacity: 0;
      flex-basis: 100%;

      &.enter {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      &__header {
        margin-bottom: 30px;
        font-size: 24px;
      }

      &__options-wrapper {
        margin: 0 0 30px 0;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
          flex-direction: row;
        }

        input {
          height: 40px;
          width: 100%;
          border: none;
          border-radius: 5px;
          box-shadow: 0 1px 0 0 #9fb1cb;
          background: $light-blue;
          color: #fff;
          // text-shadow: none;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.2s ease all;

          @include breakpoint(medium) {
            width: 150px;
          }

          & + input {
            margin: 15px 0 0 0;

            @include breakpoint(medium) {
              margin: 0 0 0 25px;
            }
          }

          &:focus,
          &:hover {
            background: $button-blue;
            color: #fff;
            border-color: $button-blue;
          }
        }
      }
    }
  }

  &__result-wrapper {
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease;
    transform: translate3d(0, 100%, 0);
    opacity: 0;

    &.enter {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    &__header,
    &__ {
      font-size: 24px;
      margin-bottom: 25px;
    }

    &__text {
      font-size: 20px;
      line-height: 1.3;
      color: #0e3c7c;
      max-width: 780px;
      margin-bottom: 35px;
    }

    &__list {
      list-style: none;
      margin: 0 0 30px 0;

      @include breakpoint(large) {
        margin: 0 0 60px 0;
      }

      li {
        color: #0e3c7c;
        font-size: 15px;
        font-weight: bold;
        margin: 0 0 0 1.25rem;

        &:before {
          content: "\e80b";
          font-family: fontello;
          margin-right: 10px;
          font-size: 12px;
        }

        & + li {
          margin-top: 10px;
        }
      }
    }

    &__job {
      display: flex;
      background: white;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
      flex-direction: column;
      transform: translateY(30px);
      opacity: 0;
      transition: all 0.3s ease 0.7s;

      &.is-active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoint(medium) {
        flex-direction: row;
      }

      & + & {
        margin-top: 10px;
      }

      &:last-of-type {
        margin-bottom: 15px;
      }

      &__date-container {
        flex-basis: 20%;
        background: #e6ebf1;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 162px;
        display: none;

        @include breakpoint(medium) {
          display: flex;
        }

        &__day {
          font-size: 48px;
          font-weight: bold;
          line-height: 0.8;
          color: #0e3c7c;
          margin: 0;
        }

        &__month {
          font-size: 24px;
          line-height: normal;
          letter-spacing: 1.6px;
          color: #0e3c7c;
          text-transform: uppercase;
        }

        &__lastApply {
          margin: 10px 23px;
          text-align: center;
          font-size: 14px;
          color: #0e3c7c;
        }
      }

      &__text-container {
        padding: 20px;
        flex-basis: 60%;
        flex-grow: 1;

        &__header {
          font-size: 20px;

          @include breakpoint(medium) {
            font-size: 24px;
          }

          @include breakpoint(large) {
            font-size: 30px;
          }
        }

        &__meta-data {
          display: flex;
          margin: 5px 0 15px 0;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          flex-wrap: wrap;

          @include breakpoint(large) {
            font-size: 16px;
          }

          p {
            margin: 5px;
            flex-basis: 47%;
            flex-grow: 1;

            @include breakpoint(medium) {
              flex-basis: 33%;
            }

            @include breakpoint(large) {
              flex-basis: 22%;
            }
          }

          .icon {
            color: #0e3c7c;
            font-size: 16px;

            @include breakpoint(large) {
              font-size: 20px;
            }
          }

          &__type {
            .icon {
              margin: 0 10px 0 0;
            }
          }
        }

        &__lastApplytext {
          font-weight: bold;
          color: #0e3c7c;

          @include breakpoint(medium) {
            display: none;
          }
        }
      }

      &__button-container {
        display: flex;
        align-items: center;
        margin: 0 20px 20px 20px;

        @include breakpoint(large) {
          justify-content: flex-end;
          margin: 0 15px 0 10px;
          flex-grow: 1;
        }

        &__button {
          @include breakpoint(medium) {
            width: 163px !important;
          }
        }
      }
    }

    &__button {
      display: none;

      @include breakpoint(medium) {
        align-self: flex-end;
        width: 268px !important;
        font-size: 18px !important;
      }
    }
  }

  &__timeline {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    margin: 30px 0 0 0;

    &__step {
      flex-basis: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      transition: all 0.3s ease;

      &.is-active {
        .line {
          flex-basis: 40%;
          flex-grow: 1;
        }
      }

      &.first {
        flex-basis: auto;
        flex-grow: 0;
      }

      &.current {
        .line {
          background-position: left bottom;
        }
      }

      .line {
        flex-basis: 0;
        height: 3px;
        flex-grow: 0;
        transition: all 0.2s ease;
        background: linear-gradient(to right, #639730 50%, #cfd8e4 50%);
        background-size: 200% 100%;
        background-position: right bottom;

        @include breakpoint(medium) {
          height: 9px;
        }
      }

      &__number {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
        color: #879ebc;
        margin: 0;
        border-radius: 100%;
        border: solid 2px #cfd8e4;
        width: 30px;
        height: 30px;
        align-items: center;
        display: flex;
        background: white;
        justify-content: center;
        transition: all 0.3s ease 0.2s;
        transform: scale(0.1);

        &.is-active {
          transform: scale(1);
        }

        @include breakpoint(medium) {
          font-size: 24px;
          width: 50px;
          height: 50px;
        }

        .current & {
          color: white;
          background-color: #639730;
          border: solid 2px #639730;
          transform: scale(1.2);
        }
      }
    }
  }

  &__button {
    align-self: flex-end;
    width: 199px !important;
  }
}
