.link-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;

  &__link-block {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;


    &:hover, &:focus {
      .link-block-wrapper__link-block__text {
        color: $light-blue;
      }
    }

    @include breakpoint(medium) {
      width: 50%;
    }

    @include breakpoint(large) {
      width: 25%;
      margin: 50px 0;
    }


    &__text {
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;
      color: $dark-blue;
      font-family: Source Sans Pro;
      letter-spacing: 0.75px;
      transition: .3s ease all;
    }
  }
}
