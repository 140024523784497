header {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease all;
}

.main-margin {
  transition: 0.3s ease all;

  @include breakpoint(medium) {
    //david
    margin-top: 346px !important;
  }
  &.lang-margin {
    @include breakpoint(medium) {
      //david
      margin-top: 195px !important;
      // margin-top: 195px !important;
    }
  }
}

.startpage.main-margin {
  @include breakpoint(medium) {
    margin-top: 95px !important;
  }
}

.module-header {
  height: 80px;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
  background: #fff;
  transition: 0.3s ease;

  @include breakpoint(medium) {
    padding: 0 15px;
    background: rgba(255, 255, 255, 0.85);
  }

  @include breakpoint(large) {
    padding: 0;
  }

  .grid-1280 {
    padding: 0;
    height: 100%;
  }

  @include breakpoint(medium) {
    height: 95px;
  }

  &.top-image {
    height: 40vh;
    width: 100vw;

    @include breakpoint(large) {
      height: 70vh;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px 15px 8px !important;

    @include breakpoint(medium) {
      padding: 0;
      padding: 15px 0px 15px 0px !important;
    }

    .top-image & {
      padding-top: 2rem !important;
    }
  }

  &__logo-wrapper {
    width: 8rem;
    transition: width 0.3s ease;

    @include breakpoint(large) {
      width: 10rem;
    }

    &__logo {
      width: 100%;

      .top-image {
        width: 14rem;
      }
    }
  }

  &__side-navigation-wrapper {
    display: flex;
    min-width: 40%;
    justify-content: flex-end;

    &__item {
      display: flex;
      flex-direction: row;
      // margin: 0 0 0 20px;
      align-items: center;
      font-size: 13px;
      letter-spacing: 0.75px;
      color: $dark-blue;
      text-transform: capitalize;
      text-decoration: none;
      transition: 0.3s ease all;
      cursor: pointer;
      i {
        font-size: 30px;
      }

      @include breakpoint(medium) {
        margin: 0 0 0 30px;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        .icon-sok,
        .icon-sprak {
          color: $light-blue;
        }

        .icon-menu {
          background: $dark-blue;
          color: #fff;
        }
      }
    }
    .hamburger-menu-desktop {
      z-index: 1000;
      @media print, screen and (max-width: 40em) {
        display: none;
      }
      &:hover,
      &:focus {
        .hamburger-menu-desktop__wrapper {
          background: #0e3c7c;
          //color: #fff;
          .line {
            background-color: white;
          }
        }
      }
    }
    .hamburger-menu-desktop,
    .hamburger-menu-phone {
      // display: flex;
      &[aria-expanded="true"] .line:nth-child(2) {
        opacity: 0;
      }

      &[aria-expanded="true"] .line:nth-child(1) {
        -webkit-transform: translateY(6px) rotate(45deg);
        -ms-transform: translateY(6px) rotate(45deg);
        -o-transform: translateY(6px) rotate(45deg);
        transform: translateY(6px) rotate(45deg);
      }

      &[aria-expanded="true"] .line:nth-child(3) {
        -webkit-transform: translateY(-5px) rotate(-45deg);
        -ms-transform: translateY(-5px) rotate(-45deg);
        -o-transform: translateY(-5px) rotate(-45deg);
        transform: translateY(-5px) rotate(-45deg);
      }
      &__wrapper {
        border: 1.5px solid #0e3c7c;
        border-radius: 50%;
        font-size: 18px;
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        color: #0e3c7c;
        transition: 0.2s ease all;
        &__spans {
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            margin-right: 8px;
          }
          text-transform: uppercase;
          font-size: 15px;
          font-weight: bold;

          display: block;
          margin: 8px auto;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        .line {
          width: 18px;
          height: 2.5px;
          background-color: $dark-blue;
          display: block;
          margin: 3px auto;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
      .menu-text {
        display: flex;
        flex-direction: row;
        margin: 0 0 0 5px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
        align-items: center;
        font-size: 13px;
        letter-spacing: 0.75px;
        color: $dark-blue;
        text-decoration: none;
        transition: 0.3s ease all;

        @include breakpoint(medium) {
          margin: 0 0 0 5px;
        }
      }
    }

    .icon-sprak,
    .icon-sok {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s ease all;

      @include breakpoint(medium) {
        height: 24px;
        width: 24px;
        font-size: 24px;
      }
    }

    .icon-menu {
      border: 1.5px solid #0e3c7c;
      border-radius: 50%;
      font-size: 18px;
      width: 33px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      color: $dark-blue;
      transition: 0.2s ease all;
    }
  }
}

.search-box-wrapper {
  order: 1;
  @include breakpoint(medium) {
    order: 0;
  }
}
/* Search box*/
.search-box-header {
  height: 0;
  width: 100%;
  background: $gray;
  transition: 0.3s ease all;
  overflow: hidden;
  opacity: 0;
  display: flex;
  z-index: 10;
  order: 1;

  @include breakpoint(medium) {
    align-items: center;
    order: 0;
  }

  &__inner {
    padding: 0;

    &__links {
      @include breakpoint(medium) {
        display: flex;
        padding-top: 30px;
        justify-content: center;
      }
    }

    &__link-row {
      @include breakpoint(medium) {
        padding: 0 20px;
      }

      &__heading-wrapper {
        display: flex;
        width: 100%;
        align-items: center;
        background: $mystic;
        padding: 10px 15px;
        border-bottom: 1px solid #cfd8e6;

        &[aria-expanded="true"] {
          border-color: rgba(0, 0, 0, 0);
        }

        &__heading {
          flex: 1 1 auto;
          color: $dark-blue;
          font-weight: bold;
          font-size: 14px;
          margin-left: 15px;
          text-align: left;
        }

        &__icon {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid $dark-blue;
          color: $dark-blue;
          border-radius: 50%;
          font-size: 12px;
        }
      }

      &__link-wrapper {
        display: flex;
        flex-direction: column;
        padding: 5px 15px 15px 15px;
        transition: 0.3s ease all;

        &[aria-hidden="false"] {
          opacity: 1;
          max-height: 400px;
          overflow: auto;
          padding: 15px 15px 10px 15px;
          background: #fff;
        }

        &__link {
          color: $deep-gray;
          font-size: 14px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          word-break: break-word;
          transition: 0.2s ease;
          span {
            border-bottom: 2px solid transparent;
            transition: 0.2s ease;
          }
          &:hover {
            span {
              color: $deep-gray;

              transition: 0.2s ease;

              border-color: inherit;
            }
          }

          &:before {
            font-family: "fontello";
            content: "\f105";
            background: $light-blue;
            color: #fff;
            height: 14px;
            width: 14px;
            font-size: 11px;
            margin-right: 5px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease all;
          }
        }
      }
    }

    &__arrow {
      width: 20px;
      height: 20px;
      background: $gray;
      position: relative;
      left: 905px;
      top: 27px;
      //david
      // top: 33px;
      margin-top: -20px;
      transform: rotate(45deg);
      opacity: 0;
      transition: 1s ease all;

      @include breakpoint(medium down) {
        display: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      .suggestions {
        display: block;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.26);
        border: solid 1px #dedede;
        background-color: #ffffff;
        list-style: none;

        transition: width 0.3s;
        margin: auto;
        align-self: center;
        margin-left: 3rem;
        position: absolute;
        z-index: 3;
        @media print, screen and (max-width: 640px) {
          top: 129px;
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          margin-left: 200px;
        }

        @include breakpoint(medium) {
          top: 80.5px;
          padding: 8px;
        }
        &__catlist {
          display: flex;

          &__name {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            color: #979797;
          }
        }

        &__border {
          height: 16px;
          border-radius: 2px;
          border-bottom: solid 2px #dedede;
        }

        li,
        em {
          @include breakpoint(small) {
            padding: 5px;
          }
          @include breakpoint(medium) {
            padding: 10px;
          }
          display: flex;
          flex-direction: column;

          background: white;

          margin: 10px auto;
          font-size: 1.2rem;
          width: 95%;

          a {
            span {
              border-bottom: 2px solid transparent;
              transition: 0.2s ease;
            }

            &:hover {
              span {
                border-color: inherit;
              }
            }
            font-size: 16px;
            color: #0e3c7c;
          }
        }
      }
      &__form {
        display: flex;
        align-items: end;
        padding: 0 15px;
        margin: 10px 0;
        flex-direction: row-reverse;

        @include breakpoint(medium) {
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }

        &__button {
          display: none;

          @include breakpoint(medium) {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 30px;
            font-size: 14px;
            margin-left: 30px;
            background: $dark-blue;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 30px;
            color: #fff;
            cursor: pointer;
            transition: 0.2s ease all;

            &:hover {
              background: $light-blue;
            }
          }
        }

        &__icon {
          color: $dark-blue;
          font-size: 34px;
          cursor: pointer;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            color: $light-blue;
          }
        }

        &__input {
          padding: 0 10px;
          font-size: 18px;
          color: $dark-blue;
          background: none;
          box-shadow: none;
          border: 0;
          border-bottom: 2px solid $dark-blue;

          @include breakpoint(medium) {
            margin-bottom: 0;
            width: 425px;
          }

          &::placeholder {
            color: #6e7d91;
          }

          &:focus {
            box-shadow: none;
            border: 0;
            border-bottom: 2px solid $light-blue;
            background: $gray;
          }
        }
      }
    }
  }
}
/* Language box*/
.lang-box-header {
  height: 0;
  width: 100%;
  background: $gray;
  align-items: center;
  transition: 0.2s ease all;
  overflow: hidden;
  opacity: 0;
  display: flex;
  order: 1;
  @media print, screen and (max-width: 420px) {
    a {
      padding: 5px 20px;
      &:nth-child(2) {
        margin: 0 15px;
      }
    }
  }

  @include breakpoint(medium) {
    order: 0;
  }

  &__inner {
    &__arrow {
      width: 20px;
      height: 20px;
      background: $gray;
      position: relative;
      left: 987px;
      top: 38px;
      margin-top: -20px;
      transform: rotate(45deg);
      opacity: 0;
      transition: 1s ease all;

      @include breakpoint(medium down) {
        display: none;
      }
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;

      &__button {
        display: inline-block;
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        font-size: 15px;
        color: $dark-blue;
        font-weight: bold;
        background: #fff;
        padding: 8px 30px;
        border-radius: 30px;
        transition: 0.3s ease all;
        float: right;
        border: 1px solid $dark-blue;

        &:hover {
          background: $dark-blue;
          color: #fff;
        }

        &.active {
          background: $dark-blue;
          color: #fff;
        }

        &:nth-child(2) {
          margin: 0 20px;
        }
      }
    }
  }
}

.expanded-header {
  overflow: scroll;
  height: calc(100vh - 80px);
  opacity: 1;
  &.lang-box-header {
    height: 100px;
  }

  @include breakpoint(medium) {
    height: 250px;
    overflow: visible;
  }
}

#lang-link {
  // display: none;
  span {
    display: none;
  }

  @include breakpoint(medium) {
    display: flex;
    span {
      display: block;
    }
  }
}

#search-link {
  i {
    font-size: 30px;

    @include breakpoint(medium) {
      font-size: 24px;
    }
  }

  span {
    display: none;

    @include breakpoint(medium) {
      display: flex;
    }
  }
}

.hamburger-menu-phone {
  display: flex;

  &__wrapper {
    height: 30px;
    width: 30px;
  }

  .line {
    height: 2px;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.sticky-header {
  position: fixed;
  @include breakpoint(medium) {
    height: 45px;
  }

  .menu-box-header__inner__arrow {
    left: 1137px;
  }

  .lang-box-header__inner__arrow {
    left: 1080px;
    top: 33px;
  }
  //david
  .search-box-header__inner__arrow {
    // top: 28px;
    // left: 1040px;
    top: 24px;
    left: 1033px;
  }

  .module-header__logo-wrapper {
    width: 8rem;
  }

  .module-header {
    @include breakpoint(medium) {
      height: 45px;
      background: #fff;
    }
  }
  .hamburger-menu-desktop {
    &[aria-expanded="true"] .line:nth-child(3) {
      -webkit-transform: translateY(-4px) rotate(-45deg);
      -ms-transform: translateY(-4px) rotate(-45deg);
      -o-transform: translateY(-4px) rotate(-45deg);
      transform: translateY(-4px) rotate(-45deg);
    }
    &[aria-expanded="true"] .line:nth-child(1) {
      -webkit-transform: translateY(4px) rotate(45deg);
      -ms-transform: translateY(4px) rotate(45deg);
      -o-transform: translateY(4px) rotate(45deg);
      transform: translateY(4px) rotate(45deg);
    }

    &__wrapper {
      width: 26px;
      height: 26px;
      &__spans {
        .line {
          width: 13px;
          height: 2px;
          background-color: #0e3c7c;
          display: block;
          margin: 2px auto;
        }
      }
    }
  }

  .module-header__side-navigation-wrapper__item {
    @include breakpoint(medium) {
      margin: 0 0 0 15px;
    }

    .icon-menu {
      @include breakpoint(medium) {
        font-size: 14px;
        width: 26px;
        height: 26px;
      }
    }

    span:not(.line) {
      max-width: 0;
      overflow: hidden;
    }
  }
}
