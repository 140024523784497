.mobile-navigation {
  height: 0vh;
  background: $gray;
  width: 100%;
  z-index: 100;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: all 0.5s ease;

  @include breakpoint(medium) {
    display: none;
  }

  &__login {
    &:focus,
    &:hover {
      text-decoration: none;
    }

    &__wrapper {
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 15px;
      color: #000;

      .login-text {
        font-size: 14px;
        font-family: "lato";
        font-weight: bold;
      }

      span {
        font-size: 18px;
      }
    }
  }

  &__container {
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      border-top: none;
      overflow: hidden;
      transition: max-height 0.5s ease, opacity 0.5s ease;

      &.mobile-navigation__container__level-3,
      &.mobile-navigation__container__level-4,
      &.mobile-navigation__container__level-5,
      &.mobile-navigation__container__level-6,
      &.mobile-navigation__container__level-7,
      &.mobile-navigation__container__level-8,
      &.mobile-navigation__container__level-9,
      &.mobile-navigation__container__level-10 {
        max-height: 0;
        opacity: 0;

        &[aria-hidden="false"] {
          opacity: 1;
        }
      }
    }

    li {
      position: relative;
      background-color: #fff;

      a {
        display: block;
        color: $dark-blue;
        text-decoration: none;
        max-width: 85%;
        word-break: break-word;
        &:focus {
          outline-color: black;
        }

        span {
          font-weight: bold;
        }
      }

      .icon-plusminus {
        position: absolute;

        &[aria-expanded="true"] {
          .icon-minus,
          .icon-plus {
            background: $dark-blue;
            color: #fff;
          }
        }

        .icon-minus,
        .icon-plus {
          border-radius: 50%;
          border: 2px solid $dark-blue;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .active-item {
      background-color: $mystic;
    }

    .active-level-3,
    .active-level-4,
    .active-level-5,
    .active-level-6,
    .active-level-7,
    .active-level-8 {
      font-weight: bold;
    }

    .visible-node {
      max-height: 1800px !important;
      opacity: 1 !important;
    }

    &__level-2 > li > a {
      padding: 15px 0px 15px 10px;
      font-size: 15px;
      font-weight: bold;
    }

    &__level-3 > li > a {
      font-size: 14px;
      padding: 8px 0px 8px 10px;
    }

    &__level-4 > li > a {
      padding: 6px 0px 6px 20px;
      font-size: 14px;
    }

    &__level-5 > li > a {
      padding: 8px 0px 8px 20px;
      padding-left: 30px;
      font-size: 14px;
    }

    &__level-6 > li > a {
      padding: 8px 0px 8px 20px;
      padding-left: 40px;
      font-size: 14px;
    }

    &__level-7 > li > a {
      padding: 8px 0px 8px 20px;
      padding-left: 50px;
      font-size: 14px;
    }

    &__level-8 > li > a {
      padding: 8px 0px 8px 20px;
      padding-left: 60px;
      font-size: 14px;
    }

    &__contact,
    &__language {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    &__bottom-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 15px 8px;
      background: $gray;

      @include breakpoint(medium) {
        display: none;
      }

      &__link {
        flex: 1 1 50%;
        font-family: "Source Sans Pro", sans-serif;
        color: $dark-blue;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 7.5px 0;

        i {
          margin-right: 10px;
          font-size: 18px;
        }
      }
    }

    &__lang-wrapper {
      background: $mystic;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 15px;
      max-height: 60px;
      overflow: visible;
      opacity: 1;
      transition: 0.2s ease all;

      &[aria-hidden="true"] {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding: 0;
      }

      &__btn {
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 27px;
        background: #fff;
        border: 2px solid $dark-blue;
        height: 30px;
        width: 100px;
        font-size: 14px;
        font-family: "Source Sans Pro", sans-serif;
        color: $dark-blue;
        font-weight: bold;
        text-transform: uppercase;

        &:nth-child(2) {
          margin: 0 15px;
        }
      }

      .active {
        background: $dark-blue;
        color: #fff;
      }
    }
  }

  .dark-blue {
    background: $dark-blue !important;
  }

  .olive {
    background: $olive !important;
  }

  .teal {
    background: $teal !important;
  }
}

.mobile-navigation__container__level-2 > li.teal > a,
.mobile-navigation__container__level-2 > li.olive > a,
.mobile-navigation__container__level-2 > li.dark-blue > a,
.mobile-navigation__container__level-2 > li.medium-blue > a {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.75px;

  .icon-minus,
  .icon-plus {
    border-color: #fff;
    width: 30px;
    height: 30px;
  }

  &[aria-expanded="true"] {
    .icon-minus,
    .icon-plus {
      background: #fff;
      color: $dark-blue;
    }
  }
}

.mobile-navigation__container__level-2 > li > a {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.75px;

  .icon-minus,
  .icon-plus {
    width: 30px;
    height: 30px;
  }
}

.mobile-navigation__container__level-2 li .icon-plusminus {
  right: 12px;
  top: -3px;
}

.mobile-navigation__container__level-3 li .icon-plusminus,
.mobile-navigation__container__level-4 li .icon-plusminus,
.mobile-navigation__container__level-5 li .icon-plusminus,
.mobile-navigation__container__level-6 li .icon-plusminus,
.mobile-navigation__container__level-7 li .icon-plusminus,
.mobile-navigation__container__level-8 li .icon-plusminus {
  right: 15px;
  top: -1px;

  .icon-minus,
  .icon-plus {
    width: 25px;
    height: 25px;
  }
}

ul.mobile-navigation__container__level-3.visible-node {
  padding: 10px 0;
  background: #fff;
}

.mobile-navigation__container li ul li {
  background: #fff;
}
