.button-block-wrapper {
  padding: 60px 0;
  background-color: #f3f5f8;

  &__heading {
    margin-bottom: 40px;
  }
}

.button-block-inner-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;

  @include breakpoint(medium) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__margin-correction {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @include breakpoint(large) {
      margin-left: -30px;
      width: calc(1280px + 30px);
    }

    &__button-wrapper {
      display: flex;
      align-items: center;
      background: #fbfbfb;
      width: 100%;
      padding: 10px 15px 10px 30px;
      border-radius: 30px;
      border: 1px solid $dark-blue;
      margin-bottom: 20px;
      transition: 0.2s ease all;
      min-width: 260px;

      @include breakpoint(medium) {
        //ändras
        // margin-left: 30px;
        width: calc(50% - 30px);
      }

      @include breakpoint(large) {
        width: calc(33% - 30px);
        margin-left: 30px;
      }

      &__text {
        flex: 1 1 auto;
        width: 90%;
        margin-right: 10px;
        font-size: 20px;
        font-weight: 600;
        color: #575757;
        transition: 0.3s ease all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__icon {
        font-size: 24px;
        color: #fff;
        background: $light-blue;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          margin-left: 0.3em;
        }
      }

      &:hover,
      &:focus {
        background: $dark-blue;

        .button-block-inner-wrapper__margin-correction__button-wrapper__text {
          color: #fff;
        }
      }
    }
  }
}
