.quote-block-wrapper {
  display: flex;
  padding: 55px 0;
}

.full-height-img {
  padding: 0;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }

  .quote-block-image-wrapper {
    height: 200px;
    width: 100%;

    @include breakpoint(medium) {
      height: auto;
      width: 30%;
    }
  }

  .quote-block-inner-wrapper {
    margin-left: 0;

    &__text-wrapper {
      padding: 80px 0;
      max-width: 930px;
    }
  }
}

.quote-block-image-wrapper {
  width: 30%;
  /*background-image: url(https://live.staticflickr.com/5662/20771177069_2c09dc01e2_b.jpg) !important;*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  &.img-right {
    order: 0;
    @include breakpoint(medium) {
      order: 1;
    }
  }
}

.quote-block-inner-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include breakpoint(medium) {
    flex-wrap: nowrap;
  }

  &__image-wrapper {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 230px;
    height: 230px;
    border-radius: 27px;
    flex: 0 0 auto;
    margin-bottom: 30px;

    @include breakpoint(medium) {
      margin-right: 70px;
    }

    &.round-img {
      border-radius: 50% !important;
    }

    &.img-right {
      order: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      flex: none;

      @include breakpoint(medium) {
        order: 1;
        margin-left: 70px;
        margin-right: 0;
      }
    }
  }

  &__text-wrapper {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    line-height: 1.2;
    color: #fff;

    &__heading {
      font-weight: bold;
      font-size: 24px;

      @include breakpoint(medium) {
        font-size: 27px;
        line-height: 1.5;
      }
    }

    .quote {
      font-style: italic;
    }

    &__bottom-row {
      display: flex;
      flex-wrap: wrap;

      &.img-right {
        @include breakpoint(large) {
          justify-content: flex-end;
        }
      }

      &__content {
        flex: 1 1 auto;
        margin-right: 20px;

        &__name {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold;
          display: block;
        }

        &__title {
          margin-top: 5px;
          font-size: 16px;
        }
      }
    }

    .CTA-button-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      @include breakpoint(medium) {
        display: unset;
      }
    }
    &__button {
      display: inline-block;
      font-family: "Source Sans Pro", sans-serif;
      text-transform: uppercase;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      background: $dark-blue;
      padding: 10px 30px;
      border-radius: 30px;
      margin-top: 20px;
      transition: 0.3s ease all;
      margin-left: 0;
      word-break: break-word;
      @include breakpoint(medium) {
        margin-left: 0;

        flex-direction: row;
      }
      &:hover,
      &:focus {
        background: $light-blue;
        color: #fff;
      }
    }

    &__CTA {
      display: flex;
      align-items: center;
      border-top: 1px solid #fff;
      padding-top: 10px;
      margin-top: 20px;

      justify-content: flex-end;

      &__link {
        display: flex;
        align-items: center;

        &__text {
          border-bottom: 2px solid transparent;
        }
        &:hover,
        &:focus {
          .quote-block-inner-wrapper__text-wrapper__CTA__link__icon {
            color: $dark-blue;
            border-color: #fff;
            background: #fff;
          }

          .quote-block-inner-wrapper__text-wrapper__CTA__link__text {
            border-color: inherit;
          }
        }

        &__text {
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 18px;
          font-family: "Source Sans Pro", sans-serif;
          transition: 0.3s ease all;
        }

        &__icon {
          font-size: 20px;
          padding-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          transition: 0.3s ease all;
          margin-left: 15px;
        }
      }
    }
  }
}
