.c-image-links {
  margin: 0 -20px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  &.two-columns {
    .c-image-links-block__wrapper__item__text-wrapper {
      @media (max-width: 1000px) {
        height: calc(100% - 290px);
      }
      height: calc(100% - 340px);
    }
    justify-content: space-between;
    .image-link-wrapper__image {
      @media (max-width: 1000px) {
        height: 250px;
      }
      height: 300px;
    }
    .c-image-links-block__wrapper__item {
      margin-left: 0;
      width: 100%;

      flex-basis: unset;
      @media (max-width: 550px) {
        max-width: unset;
        flex-basis: unset;
      }
      @include breakpoint(medium) {
        flex-basis: calc(50% - 20px);

        max-width: 48%;
      }
    }
  }
  &.three-columns {
    justify-content: space-between;
    .c-image-links-block__wrapper__item {
      margin-left: 0;

      flex-basis: unset;

      @include breakpoint(medium) {
        max-width: calc(33% - 20px);
        flex-basis: 33%;
      }
      @media (max-width: 900px) {
        max-width: calc(50% - 20px);
        flex-basis: 50%;
      }
      @media (max-width: 550px) {
        max-width: unset;
        flex-basis: unset;
        width: 100%;
      }
    }
  }
}

.c-image-links-block {
  padding: 40px 0;

  @include breakpoint(large) {
    padding: 55px 0;
  }

  &__heading {
    padding-bottom: 20px;
  }

  /* Styling for dark background */
  &.medium-blue,
  &.olive,
  &.teal {
    & * {
      color: #ffffff;
    }
  }
  &.dark-blue {
    *:not(.image-link-wrapper__button) {
      color: #fff;
    }
  }

  .tilted-border {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  &__ingress {
    display: block;
    padding-bottom: 30px;

    @include breakpoint(large) {
      padding-bottom: 60px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;

    &__item-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }

    &__item {
      &:first-of-type {
        margin-top: 20px;

        @include breakpoint(medium) {
          margin-top: 0;
        }
      }
      flex-grow: 1;
      align-items: stretch;
      flex-basis: unset;
      display: flex;
      flex-direction: column;
      margin: 0 5px;
      @include breakpoint(small) {
        flex-basis: unset;
      }
      @include breakpoint(medium) {
        flex-basis: calc(33% - 20px);

        margin: 0 0 0 20px;
        max-width: 50%;

        &:nth-of-type(3n + 1) {
          margin-left: 0;
        }
      }

      @include breakpoint(medium) {
        margin: 0 0 0 30px;
      }

      &__text-wrapper {
        word-break: break-word;
        height: calc(100% - 290px);
        @include breakpoint(medium) {
          height: calc(100% - 202px);
        }
        @include breakpoint(large) {
          height: calc(100% - 240px);
        }
        &__ingress {
          p {
            margin-bottom: 0.5rem;
          }
        }

        &__header {
          margin: 0px 0 0.3rem 0;
          font-size: 24px;
          @include breakpoint(medium) {
            font-size: 27px !important;

            margin: 20px 0 0.3rem 0;
          }
          .is-two & {
            font-size: 30px !important;
          }
        }

        &__ingress {
          font-size: 15px;
        }
      }
    }
  }
}
