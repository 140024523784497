.slider-wrapper {
  margin-left: 65px;

  &__inner {

    &__heading-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;

      &__heading {
        color: #fff;
      }
    }
  }
}


/*slider */

.slider-container {
  width: 100%;
  position: relative;


  @include breakpoint(large) {
    width: 1280px;
  }

  .tns-nav button {
    width: 18px;
    height: 18px;
    background: $gray;
    margin-left: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    transition: .2s ease all;

    &:hover, &:focus {
      background: $light-blue;
    }
  }

  .tns-nav {
    display: flex;
    justify-content: center;
  }

  .tns-nav-active {
    background: $dark-blue;
  }

  .prev-slide, .next-slide {
    position: absolute;
    font-size: 24px;
    border: 2px solid #fff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    transition: .2s ease all;
    cursor: pointer;
    z-index: 9999;
    display: none;


    @include breakpoint(medium) {
      display: inline-block;
    }


    &:hover, &:focus {
      background: #fff;
      color: $dark-blue;
    }
  }

  .prev-slide {
    top: 50%;
    left: 0px;

    @include breakpoint(large) {
    }
  }

  .next-slide {
    top: 50%;
    right: 0px;

    @include breakpoint(large) {
    }
  }
}


.my-slider {
  position: relative;

  .tns-item {
    margin: 0 !important;
    padding: 0 100px;
  }

  .grid-1280 {
    padding: 0;

    @include breakpoint(medium) {
      padding: 0 30px;
    }

    @include breakpoint(large) {
      width: 1000px !important;
    }
  }
}

.slider-item {
  position: relative;



  &:hover {
    cursor: pointer;
  }
}
