.news-list-wrapper {
  padding: 40px 15px;
  @include breakpoint(medium) {
    // padding: 55px 0;
    padding: 55px 60px;
  }
  @include breakpoint(large) {
    padding: 40px 15px 60px 15px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .ingress {
    margin-bottom: 40px;
  }

  &__margin-correction {
    display: flex;
    width: 100%;

    @include breakpoint(desktop) {
      margin-left: -30px;
      align-items: stretch;
    }
  }
}

.news-list-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include breakpoint(desktop) {
    flex-direction: row;
  }

  &__column {
    width: 100%;
    height: 100%;

    @include breakpoint(desktop) {
      width: 33%;
      margin-left: 30px;
      height: calc(100% - 60px);
    }
  }
}

.news-plug {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  display: inline-block;
  width: 100%;
  margin-top: 20px;

  @include breakpoint(desktop) {
    height: calc(100% + 30px);
    margin-top: 0;
  }

  &:hover,
  &:focus {
    .news-plug__heading span {
      transition: 0.3s ease all;
      border-color: $dark-blue;
    }
  }

  &__image {
    width: 100%;
    transition: 0.2s ease all;
    @include breakpoint(desktop) {
      height: 225px;
    }
    height: 300px;
  }

  &__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 25px;
    background: $gray;
    min-height: 45px;

    p {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0;
      font-size: 14px;
      min-width: 30%;
      line-height: 2;

      &:before {
        font-family: "fontello";
        content: "\f133";
        margin-right: 8px;
        font-weight: bold;
        color: $dark-blue;
      }
    }

    &__tag {
      font-size: 12px;
      color: $dark-blue;
      padding: 0 10px;
      display: none;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 30px;
      background: $cream;
      margin-right: 5px;

      &:nth-child(-n + 2) {
        display: inline-flex;
      }

      @include breakpoint(desktop) {
        display: inline-flex;
      }

      &.dark-blue {
        background: $dark-blue !important;
        color: #fff;
      }

      &.teal {
        background: $teal !important;
        color: #fff;
      }

      &.olive {
        background: $olive !important;
        color: #fff;
      }
      &.medium-blue {
        // background: $olive !important;
        color: #fff;
      }
    }
  }

  &__heading {
    padding: 25px 25px 10px 25px;
    font-size: 20px !important;
    transition: 0.2s ease all;
    span {
      transition: 0.3s ease all;
      border-bottom: 2px solid transparent;
    }
  }

  &__text {
    padding: 0 25px 25px 25px;
  }
}

.news-list-plug {
  margin-top: 20px;

  @include breakpoint(desktop) {
    height: calc(100% + 30px);
    margin-top: 0;
  }

  &__item {
    border-bottom: 1px solid #979797;
    display: block;
    padding: 10px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      .news-list-plug__item__heading {
        span {
          transition: 0.3s ease all;
          border-color: $dark-blue;
        }
      }
    }

    &__date {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0;
      font-size: 14px;

      &:before {
        font-family: "fontello";
        content: "\f133";
        margin-right: 8px;
        font-weight: bold;
        color: $dark-blue;
      }
    }

    &__heading {
      margin-top: 10px;
      font-size: 20px !important;
      line-height: 1.2;
      span {
        transition: 0.3s ease all;
        border-bottom: 2px solid transparent;
      }
    }

    &__text {
      font-family: "Source Sans Pro", sans-serif;
      margin: 0;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 22px;
      max-height: 61px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__tag {
      font-size: 12px;
      color: $dark-blue;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 30px;
      margin-top: 10px;
      background: $cream;

      &.dark-blue {
        background: $dark-blue !important;
        color: #fff;
      }

      &.teal {
        background: $teal !important;
        color: #fff;
      }

      &.olive {
        background: $olive !important;
        color: #fff;
      }
    }
  }

  &__more-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-top: 30px;
    border-top: 1px solid #979797;
    width: 100%;

    &:hover,
    &:focus {
      .news-list-plug__more-btn__icon {
        color: #fff;
        border-color: $dark-blue;
        background: $dark-blue;
      }

      .news-list-plug__more-btn__text {
        &:after {
          opacity: 1;
        }
      }
    }

    &__text {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 18px;
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: bold;
      transition: 0.3s ease;
      &:after {
        content: "";
        width: 0px;
        height: 2px;
        display: block;
        background: $dark-blue;
        width: 100%;
        opacity: 0;
        transition: 0.3s ease;
      }
    }

    &__icon {
      font-size: 24px;
      padding-left: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $dark-blue;
      color: $dark-blue;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      transition: 0.3s ease;
      margin-left: 15px;
    }
  }
}
