.article-page-wrapper {
  font-family: "Source Sans Pro", sans-serif;

  &__inner {
    @include breakpoint(medium) {
      margin-top: 50px;
    }

    margin-top: 25px;

    p {
      margin: 10px 0;
      display: inline-block;
    }

    .RTE-wrapper {
      a[href*="http"]:not([href*="pdf"])::after {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        color: $dark-blue;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: 0.2em;
        text-align: center;
        /* opacity: .8; */
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: 0.2em;
        content: "\f08e";
        /* font-size: 120%; */
        -webkit-font-smoothing: antialiased;
      }

      a {
        color: $dark-blue;
        border-bottom: 2px solid transparent;
        transition: 0.2s ease;

        &:hover {
          transition: 0.2s ease;
          border-color: $dark-blue;
        }
      }
    }

    &__image {
      width: 100%;
      margin: 30px 0 30px 0;

      @include breakpoint(large) {
        width: 830px;
        margin: 30px 0 30px -25px;
      }

      img {
        width: 100%;
      }

      span {
        width: 100%;
        background: $gray;
        display: block;
        padding: 5px 0 5px 40px;
        font-size: 15px;
        font-weight: 600;
        color: $dark-gray;
        font-family: "Source Sans Pro", sans-serif;

        @include breakpoint(large) {
          width: 850px;
          margin-left: -10px;
          padding: 5px 0 5px 40px;
        }
      }
    }

    &__inforow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 14px;
      color: $dark-blue;
      margin: 10px 0 20px 0;

      @include breakpoint(large) {
        flex-wrap: nowrap;
      }

      &__date {
        margin-bottom: 10px;
        margin-right: 20px;

        @include breakpoint(large) {
          max-width: 30%;
        }

        span:before {
          font-family: "fontello";
          content: "\f133";
          margin-right: 8px;
          font-weight: bold;
        }

        @include breakpoint(large) {
          margin-bottom: 0;
        }
      }

      &__tags {
        margin-bottom: 10px;
        margin-right: 20px;

        @include breakpoint(large) {
          max-width: 55%;
        }

        span {
          font-weight: bold;

          &:before {
            font-family: "fontello";
            content: "\e80a";
            margin-right: 8px;
          }
        }

        a {
          color: $dark-blue;
          text-decoration: underline !important;
          margin-right: 10px;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            color: $light-blue;
          }
        }
      }

      &__share {
        @include breakpoint(large) {
          max-width: 20%;
        }

        span {
          text-transform: capitalize;
          font-weight: bold;

          &:before {
            font-family: "fontello";
            content: "\f1e0";
            margin-right: 8px;
          }
        }

        a {
          color: $dark-blue;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            color: $light-blue;
          }
        }
      }
    }

    &__authorRow {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      color: $dark-blue;
      font-family: "Source Sans Pro", sans-serif;
      border-top: 1px solid #979797;
      padding-top: 20px;
      margin: 20px 0;

      span {
        text-transform: capitalize;
        margin-bottom: 10px;

        @include breakpoint(medium) {
          margin-bottom: 0;
        }

        b {
          text-transform: uppercase;
          margin-right: 5px;
        }
      }

      &__share {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          text-transform: uppercase;
          font-weight: bold;
          margin-right: 20px;
        }

        a {
          color: #fff;
          transition: 0.2s ease all;
          background: $dark-blue;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          font-size: 18px;
          align-items: center;
          justify-content: center;
          display: flex;
          margin-right: 10px;

          &:hover,
          &:focus {
            background: $light-blue;
          }
        }
      }
    }
  }
}
