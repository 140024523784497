.landing-page-wrapper {
  &__hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 460px;
    display: flex;
    justify-content: center;

    position: relative;
    @include breakpoint(large) {
      justify-content: center;
      flex-direction: column;
    }

    @include breakpoint(small) {
      align-items: flex-end;
    }
    @include breakpoint(medium) {
      align-items: center;
    }

    &__vid {
      display: inline-block;
      z-index: 0;
      object-fit: cover;
      position: absolute;
    }

    .breadcrumb-wrapper {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }

    & + .breadcrumb-wrapper {
      @include breakpoint(medium) {
        display: none;
      }

      &__inner-desktop {
        @include breakpoint(large) {
          display: flex;
        }
      }
    }

    &__text-wrapper {
      width: calc(100% - 40px);
      margin-bottom: 40px;
      z-index: 1;

      @include breakpoint(medium) {
        margin: 0 20px;
      }

      @include breakpoint(large) {
        width: 1310px;
        margin: 0 auto;
      }
      // &__inner.teal {
      //   p {
      //     color: white;
      //   }
      // }

      &__inner {
        background: rgba($gray, 0.9);
        padding: 20px 20px;
        display: flex;
        align-content: center;

        width: 190px;

        &.teal,
        &.dark-blue,
        &.olive-green {
          p {
            color: white;
          }
        }

        @include breakpoint(medium) {
          padding: 20px 30px;
          width: 450px;
        }

        @include breakpoint(large) {
          padding: 20px 73px;
        }

        p {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 16px;
          font-weight: bold;
          word-break: break-word;
          line-height: 1.25;
          color: $dark-blue;
          margin-bottom: 0;

          @include breakpoint(medium) {
            font-size: 30px;
          }
        }
      }
    }

    &__pause {
      display: flex;
      justify-content: flex-end;

      &__btn {
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 30px;
        padding: 10px 20px;
        border-radius: 30px;
        color: #fff;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-size: 18px;
        transition: 0.2s ease all;
        z-index: 98;
        width: 130px;

        &:hover {
          background: $light-blue;
          color: #fff;
          border-color: $light-blue;
        }

        #play-pause-icon {
          margin-left: 0;
          font-size: 24px;
        }

        #play-pause-text {
          transition: 0.5s ease all;
        }
      }
    }
  }
}
