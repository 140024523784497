.main-job {
  padding-top: 0;
}

.jobad-page-wrapper {
  &__hero {
    display: flex;

    @include breakpoint(medium) {
      min-height: 470px;
    }

    &__left {
      width: 40%;
      background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(255, 255, 255, 0) 50%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        url(https://live.staticflickr.com/5662/20771177069_2c09dc01e2_b.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: none;

      @include breakpoint(medium) {
        display: flex;
      }
    }

    &__right {
      background: linear-gradient(to bottom right, #0d97f2, #0e3c7c);
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @include breakpoint(medium) {
        width: 60%;
      }

      &__text {
        padding: 20px 15px;

        @include breakpoint(medium) {
          margin-left: 65px;
          width: calc(100% - 65px);
          padding: 0;
        }

        @include breakpoint(large) {
          width: 715px;
        }

        &__button {
          display: inline-block;
          font-family: "Source Sans Pro", sans-serif;
          text-transform: uppercase;
          font-size: 18px;
          color: $light-blue;
          font-weight: bold;
          background: #fff;
          padding: 5px 40px;
          border-radius: 30px;
          margin-top: 40px;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            background: $light-blue;
            color: #fff;
          }
        }

        &__cat {
          font-family: "Source Sans Pro", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
        }

        &__heading {
          font-size: 30px !important;
          line-height: 1.2 !important;
          color: #fff;
          margin-bottom: 30px;
          word-break: break-word;

          @include breakpoint(medium) {
            font-size: 38px !important;
          }
        }

        &__row {
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;
          align-items: center;

          i {
            color: #fff;
            margin-right: 5px;
          }
          .email-link {
            transition: 0.2s ease;
            border-bottom: 1px solid white;
            &:hover {
              border-color: transparent;
            }
          }

          p {
            font-family: "Source Sans Pro", sans-serif;
            color: #fff;
            margin-bottom: 0;

            span {
              font-weight: bold;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  &__inner {
    margin: 50px auto;

    &.grid-780 {
      @include breakpoint(medium) {
        width: 740px;
      }

      @include breakpoint(large) {
        width: 780px;
      }
    }
    &__rep {
      a {
        @include standard-link;
      }
    }

    &__button {
      background: $light-blue;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: "Source Sans Pro", sans-serif;
      display: inline-block;
      padding: 10px 45px;
      margin-top: 30px;
      border-radius: 30px;
      transition: 0.2s ease all;
      box-shadow: 0 1px 0 0 #9fb1cb;

      &:focus,
      &:hover {
        background: $dark-blue;
        color: #fff;
      }
    }

    .ingress {
      font-size: 24px !important;

      @include breakpoint(medium) {
        font-size: 28px !important;
      }
    }

    h2,
    h3 {
      font-size: 28px;
      margin-top: 40px;

      @include breakpoint(medium) {
        font-size: 30px;
      }
    }

    h3 {
      margin-top: 40px;
    }

    p {
      margin: 10px 0;
      display: inline-block;
    }

    ul {
      margin: 10px 0px 10px 25px;
      color: #252525;
      font-family: "Source Sans Pro", sans-serif;
      line-height: 1.55;
      font-size: 18px;
      font-weight: normal;

      li {
        margin: 5px 0;

        &:before {
          content: "\e80b";
          font-family: fontello;
          margin-left: -25px;
          margin-right: 10px;
          color: $dark-blue;
        }
      }
    }

    &__contact {
      font-size: 24px !important;
      margin-top: 40px;
      line-height: 1.25;

      @include breakpoint(medium) {
        font-size: 24px !important;
      }
    }
  }
}
