.c-contact-widget {
  padding: 40px 0;

  @include breakpoint(medium) {
    padding: 55px 0;
  }
}
.contact-widget-container {
  &__inner {
    .ingress {
      margin: 30px 0 50px 0;
    }

    &__heading {
      // padding: 0 0 20px 0;
      margin-bottom: 20px;
    }

    &__ingress {
      font-family: "Source Sans Pro", sans-serif;
      line-height: 1.17;
      font-size: 18px;
      @include breakpoint(medium) {
        font-size: 24px;
      }
    }
  }
}

.contact-widget-wrapper {
  display: flex;
  flex-wrap: wrap;

  &._simpleList {
    flex-direction: column;
  }

  .contact-card {
    flex-direction: column;

    margin-bottom: 20px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);

    &._simpleList {
      padding-top: 0;
      padding-left: 0;

      .contact-card {
        &__info-wrapper {
          padding-left: 0;
          &__bottom {
            display: block;
          }
        }
      }
    }

    &._block {
      .contact-card__info-wrapper__bottom__column__heading {
        font-size: 18px;
        display: none;
        @include breakpoint(medium) {
          display: unset;
        }
      }
      .round-image-container {
        // display: flex;
        align-items: center;
        display: flex;
        margin-left: 10px;

        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        @include breakpoint(medium) {
          margin-left: 0px;
        }
      }
      height: 100%;
      width: 100%;
      background: white;
      // flex-direction: column;
      flex-direction: row;
      align-items: flex-start;

      display: flex;
      flex-direction: row;
      // display: flex;
      // padding-top: 15px;
    }

    @include breakpoint(medium) {
      /*height: 160px;*/
      flex-direction: row;
    }

    &:nth-child(2n) {
      background: $gray;
    }

    p {
      margin: 0;
      display: block;
    }

    &__image-wrapper {
      float: left;
      width: 80px;
      height: 80px;

      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-top: 25px;

      @include breakpoint(medium) {
        margin-top: 0;
        width: 163px;
        height: 163px;
      }
    }
    .only-mobile {
      margin-right: 5px;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      transition: 0.2s ease all;
      font-size: 18px;
      @include breakpoint(medium) {
        display: none;
      }
    }

    .round {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-self: center;
      margin-top: 25px;

      @include breakpoint(medium) {
        margin: 15px 20px;
        width: 120px;
        height: 120px;
      }
    }

    &__info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px 20px;

      @include breakpoint(medium) {
        padding: 15px 25px;
      }
      @media print, screen and (max-width: 350px) {
        padding: 25px 10px;
      }

      &__heading {
        word-break: break-all;

        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        color: $dark-blue;
        font-family: "Source Sans Pro", sans-serif;
      }

      &__title {
        font-size: 14px;
        color: $dark-blue;
        font-family: "Source Sans Pro", sans-serif;
      }

      &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        @include breakpoint(medium) {
          flex-direction: row;
        }

        &__column {
          display: flex;
          flex-direction: column;

          &:nth-child(2n) {
            @include breakpoint(medium) {
              margin-left: 80px;
            }
          }
          a {
            border-bottom: 1px solid transparent;
            &:hover {
              border-color: inherit;
            }
          }
          &__heading {
            color: $dark-blue;
            font-weight: bold;
            font-family: "Source Sans Pro", sans-serif;
            transition: 0.2s ease all;

            .icon-phone {
              margin-right: 5px;
            }

            .icon-mail-alt {
              margin-right: 8px;
            }
          }

          &__text {
            word-break: break-all;

            font-family: "Source Sans Pro", sans-serif;
            transition: 0.2s ease all;
            border-bottom: 1px solid transparent;
            &:hover {
              border-color: inherit;
              // color: $light-blue !important;
            }

            @include breakpoint(medium) {
              margin-left: 32px !important;
            }
          }
        }
      }
    }
  }

  .contact-card.columns {
    /*padding: 0 15px 15px 15px;*/
    // &:nth-child(2n) {
    //   background: $gray;
    // }
    // box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background: none;
    flex-direction: column;
    box-shadow: none;

    height: unset;
    width: 100%;
    @include breakpoint(small) {
    }
    @include breakpoint(medium) {
      // background: white !important;
      width: 50%;
    }
    @include breakpoint(large) {
      width: 33%;
    }

    &:first-child {
      padding-left: 0;
    }

    &:nth-child(4) {
      padding-left: 0;
    }
    .contact-card__info-wrapper {
      padding: 14px 0px 10px 10px;
      @include breakpoint(medium) {
        padding: 14px 0px 10px 20px;
      }
    }
    .contact-card__image-wrapper {
      width: 80px;
      height: 80px;
      margin-top: 10px;
    }
    .only-mobile {
      margin-right: 5px;
      font-weight: bold;
      font-family: "Source Sans Pro", sans-serif;
      transition: 0.2s ease all;
      font-size: 18px;
      @include breakpoint(medium) {
        display: none;
      }
    }
    .contact-card__info-wrapper__bottom__column {
      display: flex;
      align-items: center;
      @include breakpoint(medium) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .contact-card__info-wrapper__bottom {
      flex-direction: column;
    }

    .contact-card__info-wrapper__bottom__column:nth-child(2n) {
      margin-left: 0;
      @include breakpoint(medium) {
        margin-top: 20px;
      }
    }

    .contact-card__info-wrapper__bottom__column__heading {
      font-size: 18px;
      display: none;
      @include breakpoint(medium) {
        display: unset;
      }
    }

    .contact-card__info-wrapper__bottom__column__text {
      font-size: 16px;
    }
  }
}
.icon-row {
  display: flex;
  align-items: center;
  width: fit-content;
  p {
    border-bottom: 1px solid transparent;
    transition: 0.2s ease all;
  }

  &:hover {
    p {
      border-color: inherit;
      transition: 0.2s ease all;
    }
  }
}
