.start-page-wrapper {
  &__top-image {
    position: relative;
    align-items: flex-end;
    display: flex;
    height: 60vh;
    min-height: 460px;
    align-items: flex-end;

    @include breakpoint(medium) {
      min-height: 600px;
      align-items: center;
    }
    @include breakpoint(large) {
      justify-content: center;
      flex-direction: column;
    }

    &__img {
      align-items: flex-end;

      @include breakpoint(medium) {
        min-height: 600px;
        align-items: center;
      }
      @include breakpoint(large) {
        justify-content: center;
        flex-direction: column;
      }
    }

    &__vid {
      display: inline-block;
      z-index: 1;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__text-wrapper {
      width: calc(100% - 40px);
      margin-bottom: 40px;
      z-index: 1;

      @include breakpoint(medium) {
        margin: 0 20px;
      }

      @include breakpoint(large) {
        width: 1310px;
        margin: 0 auto;
      }

      &__inner {
        background: rgba($dark-blue, 0.7);
        padding: 20px 20px;
        display: flex;
        align-content: center;
        margin-bottom: 60px;
        width: 250px;

        @include breakpoint(medium) {
          padding: 20px 30px;
          margin-bottom: 0;
          width: 550px;
        }

        @include breakpoint(small) {
        }

        @include breakpoint(large) {
          padding: 20px 73px;
        }

        p {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 20px;
          font-weight: bold;
          word-break: break-word;
          line-height: 1.25;
          color: #fff;
          margin-bottom: 0;

          @include breakpoint(medium) {
            font-size: 30px;
          }
          @include breakpoint(large) {
            font-size: 40px;
          }
        }
      }
    }

    &__pause {
      display: flex;
      justify-content: flex-end;

      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 30px;
        padding: 10px 20px;
        border-radius: 30px;
        color: #fff;
        letter-spacing: 0.5px;
        cursor: pointer;
        font-size: 18px;
        transition: 0.2s ease all;
        z-index: 98;
        width: 130px;

        &:hover {
          background: $light-blue;
          color: #fff;
          border-color: $light-blue;
        }
        &:focus {
          outline-color: white;
        }

        #play-pause-icon {
          margin-left: 0;
          font-size: 24px;
        }

        #play-pause-text {
          transition: 0.5s ease all;
        }
      }
    }
  }
}
