.skip-to-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  background: $gray;

  &:focus {
    overflow: auto;
    max-height: 60px;
    padding: 15px 0;
    text-decoration: none;
  }

  &__text {
    flex: 0 1 auto;
    color: $dark-blue;
    font-size: 18px;
    font-weight: bold;
  }
}
