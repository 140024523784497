//todo fix padding
.c-job-list-widget {
  background: #f3f5f8;
  padding: 40px 0;
  @include breakpoint(medium) {
    padding: 55px 0;
  }
}

.job-list-1 {
  padding: 30px 0;

  &__inner {
    &:nth-of-type(1) {
      padding-top: 0;
      margin-top: 0;
    }
    background: white;
    /* margin-top: 11px; */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);

    &:hover,
    :focus {
      .job-list-1__inner__info-wrapper__bottom__button-wrapper__button {
        background-color: $button-blue;
        text {
          color: white;
        }
      }
      .job-list-1__inner__image-wrapper {
        opacity: 0.8;
      }
    }

    @include breakpoint(small) {
      flex-direction: row;
    }

    &__heading {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0.5rem;
      margin: 0;
    }

    &__image-wrapper {
      @media print, screen and (max-width: 500px) {
        display: none;
      }
      transition: 0.2s ease;

      @include breakpoint(small) {
        margin-top: 0;
        min-width: 160px;
        min-height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    display: flex;
    &__info-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 20px;
      @include breakpoint(medium) {
        padding: 0 20px;
      }
      &__top {
        margin-bottom: 0.5rem;
        &__heading {
          font-size: 20px;
          line-height: 1;
          font-weight: bold;
          color: $dark-blue;
          font-family: "Source Sans Pro", sans-serif;
          max-width: 900px;
        }

        &__ingress {
          margin-top: 0.5rem;
          max-width: 90%;
          font-size: 16px;
          font-family: "Source Sans Pro", sans-serif;

          @include breakpoint(medium) {
            margin-top: 6px;
          }
        }
      }

      &__bottom {
        @media print, screen and (min-width: 360px) {
          align-items: center;
        }

        @media print, screen and (max-width: 500px) {
          flex-direction: row;
        }
        @media print, screen and (max-width: 360px) {
          flex-direction: column;
        }
        display: flex;

        @include breakpoint(medium) {
          flex-direction: row;
          align-items: center;
        }

        &__apply {
          display: flex;
          flex: 1;

          // display: inline-block;

          &__text {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            font-size: 14px;
            font-family: "Source Sans Pro", sans-serif;
            display: inline-block;
            transition: 0.2s ease all;
            color: $dark-blue;
            background: #f3f5f8;
          }
        }
        &__button-wrapper {
          &__button {
            display: inline-block;
            font-family: "Source Sans Pro", sans-serif;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            background: $light-blue;
            padding: 3px 11px;
            border-radius: 30px;
            transition: 0.3s ease all;
            word-break: break-word;
            @media print, screen and (min-width: 800px) {
              font-size: 16px;
            }

            @include breakpoint(medium) {
              padding: 3px 20px;
            }
            @include breakpoint(small) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// ----------   job-list-2 Columns -----------

.job-list-2 {
  padding: 30px 0;
  @media print, screen and (min-width: 1120px) {
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    font-size: 14px;
  }
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &__inner {
    &:nth-of-type(2) {
      @media print, screen and (min-width: 1120px) {
        margin-right: 0;
      }
    }
    display: flex;
    margin-right: 0;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background: white;

    @media print, screen and (min-width: 1120px) {
      // margin-right: 20px;

      flex-direction: row;
      flex: 0 0 49%;
      max-width: 50%;
    }

    &:hover,
    :focus {
      .job-list-2__inner__info-wrapper__bottom__button-wrapper__button {
        background-color: $button-blue;
        text {
          color: white;
        }
      }
      .job-list-2__inner__image-wrapper {
        opacity: 0.8;
      }
    }

    &__image-wrapper {
      transition: 0.2s ease;
      @media print, screen and (max-width: 500px) {
        display: none;
      }

      @include breakpoint(small) {
        margin-top: 0;
        min-width: 160px;
        min-height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    display: flex;
    &__info-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 20px;

      @include breakpoint(medium) {
        padding: 0 25px;
      }
      &__top {
        margin-bottom: 1rem;
        &__heading {
          margin-top: 1rem;

          font-size: 20px;
          line-height: 1;
          font-weight: bold;
          color: $dark-blue;
          font-family: "Source Sans Pro", sans-serif;
          max-width: 900px;
        }

        &__ingress {
          font-size: 14px;
          margin-bottom: unset;
          font-family: "Source Sans Pro", sans-serif;
          @include breakpoint(small) {
            font-size: 18px;
          }
        }
      }

      &__bottom {
        @media print, screen and (min-width: 360px) {
          align-items: center;
        }
        margin-bottom: 1rem;
        &:focus,
        &:hover {
          .contact-card__info-wrapper__bottom__column__heading,
          .r4dcontact-card__info-wrapper__bottom__column__text {
            color: $light-blue;
          }
        }
        display: flex;
        flex-direction: row;

        margin-top: 5px;
        @media print, screen and (max-width: 360px) {
          flex-direction: column;
        }

        &__apply {
          display: flex;
          flex: 1;
          // display: inline-block;

          &__text {
            font-size: 14px;
            background: #f3f5f8;
            display: flex;
            align-items: center;
            font-family: "Source Sans Pro", sans-serif;
            // display: inline-block;
            transition: 0.2s ease all;
            color: $dark-blue;
            margin-bottom: unset;
            padding: 0.5rem;

            @include breakpoint(medium) {
            }
          }
        }

        &__button-wrapper {
          &__button {
            display: inline-block;
            font-family: "Source Sans Pro", sans-serif;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            background: $light-blue;
            padding: 3px 11px;
            border-radius: 30px;
            transition: 0.3s ease all;
            word-break: break-word;
            @media print, screen and (min-width: 800px) {
              font-size: 16px;
            }

            @include breakpoint(medium) {
              padding: 3px 20px;
            }
            @include breakpoint(small) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .job-list-2__inner__info-wrapper,
  .job-list-1__inner__info-wrapper {
    width: 90%;
  }
}
