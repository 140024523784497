.rss-feed-wrapper {
  // background: $gray;
  min-height: 400px;
  padding: 40px 15px;

  @include breakpoint(medium) {
    padding: 55px 60px;
  }
}

.rss-feed-inner {
  &__heading {
    padding: 20px 30px;
  }
  form {
    width: 100%;
  }

  &__article-box {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    margin-top: 13px;
    transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
    padding: 9px 30px;

    &__top {
      @media print, screen and (max-width: 650px) {
        flex-direction: column;
      }

      display: flex;
      justify-content: space-between;
      &__link {
        display: flex;
        max-width: 800px;
        &:hover,
        &:focus {
          color: $dark-blue;
          // text-decoration: underline;
        }

        &__heading {
          display: inline-block;
          color: $dark-blue;
          line-height: 1.3;
          transition: 0.3s ease all;
          font-size: 23px;
          span {
            border-bottom: 2px solid transparent;
          }
          &:focus,
          &:hover {
            span {
              border-color: $dark-blue;
              transition: 0.3s ease all;
            }
          }
        }
        i {
          transition: 0.2s ease all;

          color: $dark-blue;
          font-size: 23px;
          margin-left: 10px;
        }
      }
      &__date {
        display: inline-flex;
        color: $dark-gray;
        font-weight: 600;
        margin-top: 6px;
        @include breakpoint(medium) {
          margin-left: 20px;
        }
        i {
          color: $dark-blue;
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }

    &__text {
      max-width: 800px;
    }
  }
}
