.course-listing-page-wrapper {
  padding: 20px 0 40px;
}

.cl-wrapper {
  width: 100%;
  padding: 40px 0;
  display: flex;
  height: 180px;
  align-items: center;

  @include breakpoint(medium) {
    height: 280px;
    padding: 60px 0;
    // background: linear-gradient(to bottom right, rgba($light-blue,.9) 0%, rgba($dark-blue,.9) 50%) !important;
  }
  &__inner {
    h1 {
      color: $dark-blue;
      @include breakpoint(medium) {
        color: white;
      }
    }
  }
}

.select__value-container {
  height: 100%;
  align-content: center;
}
.cl-inner {
  form {
    width: 100%;
  }
  // select {
  //   -webkit-appearance: none;
  //   -moz-appearance: none;
  //   -o-appearance: none;
  // }
  &__control {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    align-items: center;
    &__sort-row {
      font-family: "Source Sans Pro", sans-serif;
      // font-weight: 600;
      font-size: 20px;
      color: $dark-gray;

      &__heading {
        margin-right: 20px;
      }

      &__option {
        color: $dark-gray;
        transition: 0.2s ease all;
        margin-right: 10px;

        &:focus,
        &:hover {
          text-decoration: underline !important;
          color: $dark-blue;
        }

        &.active {
          text-decoration: underline !important;
          font-weight: bold;
        }
      }
    }
  }
}

.c-course-list-widget {
  background: #f3f5f8;
  padding: 55px 0;
}
.course-list-wrapper {
  &__inner {
    &__button-wrapper {
      display: flex;
      justify-content: flex-end;
      &__more-btn {
        transition: 0.2s ease;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding-top: 30px;
        //   border-top: 1px solid #979797;
        &:hover,
        &:focus {
          .course-list-wrapper__inner__button-wrapper__more-btn__icon {
            color: #fff;
            border-color: $light-blue;
            background: $light-blue;
            transition: 0.2s ease;
          }

          .course-list-wrapper__inner__button-wrapper__more-btn__text {
            color: $light-blue;
            transition: 0.2s ease;
          }
        }

        &__text {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 18px;
          text-transform: uppercase;
          color: $dark-blue;
          font-weight: bold;
          transition: 0.3s ease all;
        }

        &__icon {
          font-size: 24px;
          padding-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $dark-blue;
          color: $dark-blue;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          text-align: center;
          transition: 0.3s ease all;
          margin-left: 15px;
        }
      }
    }
    //  background: #f3f5f8;
  }
}
.course-list {
  background: #f3f5f8;

  padding: 30px 0;
  &__inner {
    &:hover,
    &:focus {
      .course-list__inner__image-wrapper {
        opacity: 0.8;
      }
      .course-list__inner__info-wrapper__right__button-wrapper div {
        color: #fff;
        border-color: $light-blue;
        background: $light-blue;
      }
    }
    background: white;
    /* margin-top: 11px; */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);

    @include breakpoint(small) {
      flex-direction: row;
    }

    &:nth-child(2n) {
      background: $gray;
    }
    &__heading {
      font-size: 30px;

      margin-bottom: 10px;
    }

    p {
      margin: 0;
      font-size: 18px;

      @media print, screen and (max-width: 800px) {
        font-size: 16px;
      }

      //display: block;
    }

    &__image-wrapper {
      transition: 0.3s ease all;

      @media print, screen and (max-width: 500px) {
        display: none;
      }
      font-size: 24px;

      b {
        font-size: 48px;
      }

      @include breakpoint(small) {
        margin-top: 0;
        // width: 160px;
        // height: 160px;
        min-width: 160px;
        min-height: 160px;
        color: $dark-blue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // align-self: center;
        overflow: hidden;
        background-color: #e6ebf1;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    display: flex;
    &__info-wrapper {
      padding: 20px 10px 10px 20px;
      flex-grow: 1;
      display: flex;

      justify-content: space-between;
      align-items: center;
      //  padding: 5px 20px;
      @include breakpoint(medium) {
        //padding: 10px 20px;
      }
      &__left {
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;
        &__text {
          margin-bottom: 17px;
        }
        &__heading {
          font-size: 30px;
          line-height: 1;
          margin-bottom: 0.5rem;
          font-weight: bold;
          color: $dark-blue;
          font-family: "Source Sans Pro", sans-serif;
          max-width: 900px;
        }

        &__ingress {
          // margin-top: 0.5rem;
          display: unset;
          //  color: $dark-blue;
          font-family: "Source Sans Pro", sans-serif; //max-width: 90%;

          @include breakpoint(medium) {
            font-size: 16px;

            //  margin-top: 6px;
          }
        }

        p {
          // font-weight: 600;
        }
        i {
          margin-left: 0;
          font-size: 22px;
        }

        &:focus,
        &:hover {
          .contact-card__info-wrapper__bottom__column__heading,
          .r4dcontact-card__info-wrapper__bottom__column__text {
            color: $light-blue;
          }
        }

        &__row {
          display: flex;
          flex: 1;
          flex-wrap: wrap;
          &__item {
            display: flex;
            align-items: center;
            margin-right: 40px;
            i {
              color: $dark-blue;
            }
            &__text {
              font-family: "Source Sans Pro", sans-serif;
              display: inline;
              transition: 0.2s ease all;
              color: black;

              // padding: 5px;
            }
          }
        }
      }
      &__right {
        flex-basis: 20%;
        display: flex;
        justify-content: center;
        &__button-wrapper {
          div,
          a {
            display: inline-block;
            font-family: "Source Sans Pro", sans-serif;
            text-transform: uppercase;
            cursor: pointer;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            background: $dark-blue;
            padding: 3px 11px;
            border-radius: 30px;
            transition: 0.3s ease all;
            // margin-left: 30px;
            word-break: break-word;
            @media print, screen and (min-width: 800px) {
              font-size: 16px;
            }

            @include breakpoint(medium) {
              padding: 3px 20px;
            }
            @include breakpoint(small) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .course-list-2__inner__info-wrapper,
  .course-list-1__inner__info-wrapper {
    width: 90%;
  }
}
