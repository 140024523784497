﻿.cookie-wrapper {
  background: $cream;
  padding: 30px 0;
  display: none;
  order: 1;
  z-index: 10;
  top: 0;
  position: fixed;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  overflow: scroll;

  @include breakpoint(medium) {
    height: auto;
    order: 0;
    overflow: visible;
    position: relative;
    box-shadow: none;
  }

  &[aria-hidden="false"] {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .cookie-wrapper-inner__toggle-wrapper__accept {
    cursor: pointer;
  }
}

.cookie-wrapper-inner {
  &__info {
    span {
      font-family: "Source Sans Pro", sans-serif;
      color: $dark-blue;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      color: $dark-blue;
      font-size: 12px;
      margin: 15px 0;

      @include breakpoint(medium) {
        font-size: 16px;
      }
    }
  }

  &__toggle-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint(medium) {
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }

    @include breakpoint(large) {
      margin-left: 190px;
    }

    &__toggle {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      margin-bottom: 20px;

      @include breakpoint(large) {
        margin-bottom: 0;
      }

      input[type="checkbox"] {
        opacity: 0;
        margin-left: 0 !important;
        width: 0;
        z-index: 0;

        &:focus + label {
          outline: 2px dashed #515151;
        }
        &:checked + label {
          background-color: $dark-blue;
        }

        &:checked + label:after {
          left: 33px;
        }

        &:disabled + label {
          background-color: #a2a2a2;
          cursor: not-allowed;
        }
      }

      label {
        transition: all 200ms ease-in-out;
        display: inline-block;
        position: relative;
        height: 30px;
        width: 60px;
        border-radius: 40px;
        cursor: pointer;
        background-color: #ddd;
        color: transparent;
        margin-left: 0;
        margin-right: 10px;

        &:after {
          transition: all 200ms ease-in-out;
          content: " ";
          position: absolute;
          height: 23px;
          width: 23px;
          border-radius: 50%;
          background-color: white;
          top: 3px;
          left: 3px;
          right: auto;
          box-shadow: 1px 1px 1px gray;
        }
      }

      &__label {
        font-family: "Source Sans Pro", sans-serif;
        color: $dark-blue;
        font-size: 14px;
      }
    }

    &__accept {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      padding: 12px 20px;
      background: $dark-blue;
      border-radius: 3px;
      border: 1px solid $dark-blue;
      transition: 0.2s ease all;
      cursor: pointer;

      &:hover,
      &:focus {
        background: #fff;
        color: $dark-blue;
      }
    }
  }

  &__detail {
    h2 {
      padding: 20px 0;
    }
    @include breakpoint(medium) {
      // display: inline-block;
      width: 100%;
      padding: 55px 0px;
    }

    @include breakpoint(large) {
      margin-left: 190px;
      margin: 0 auto;
    }

    &__btn-wrapper {
      border-bottom: 1px solid $dark-blue;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;

      &__show-more {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        color: $dark-blue;
        font-weight: bold;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        margin: 30px 0 13px 0;
        transition: 0.2s ease all;
        cursor: pointer;

        &:focus,
        &:hover {
          color: $light-blue;
        }
      }
    }

    &__container {
      width: 100%;
      // padding: 40px 0;

      &[aria-hidden="false"] {
        display: inline-block;
      }

      &__button-wrapper {
        margin-bottom: 30px;

        .cookie-info-btn {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 14px;
          color: $dark-blue;
          padding: 12px 20px;
          border: 1px solid #979797;
          border-radius: 3px;
          margin-right: 5px;
          transition: 0.2s ease all;

          &:hover,
          &:focus {
            background: $mystic;
          }
        }

        .active {
          background: $mystic;
          font-weight: bold;
        }
      }
    }

    .detail__container-inner {
      display: flex;
      flex-direction: column;
      &__left {
        display: flex;
        align-items: flex-start;

        button {
          font-family: "Source Sans Pro", sans-serif;
          font-size: 14px;
          color: $dark-blue;
          padding: 10px 15px;
          margin: 10px 0;
          cursor: pointer;

          &[aria-expanded="true"] {
            background: $mystic;
            font-weight: bold;
          }

          &:hover,
          &:focus {
            text-decoration: underline !important;
          }
        }
      }

      &__right {
        overflow-y: auto;
        flex: 1 1 auto;
        font-family: "Source Sans Pro", sans-serif;
        display: none;
        table {
          min-width: 700px;
          overflow-x: auto;
          @include breakpoint(medium) {
            overflow-x: hidden;
          }
        }

        &[aria-hidden="false"] {
          display: inline-block;
        }

        p {
          font-size: 14px;
          color: $dark-blue;
          margin-bottom: 20px;
          margin-top: 7px;
        }

        tbody {
          border: 0;
        }

        tr {
          display: flex;
          flex-direction: row;
          flex-basis: 100%;
        }

        th {
          font-size: 12px;
          font-weight: bold;
          color: $dark-blue;
          background: $mystic;
          text-align: left;
        }

        td {
          background: $cream !important;
          font-size: 12px;
          word-break: break-word;
        }

        th,
        td {
          &:nth-child(n + 1) {
            flex-basis: 15%;
          }
          &:nth-child(n + 2) {
            flex-basis: 10%;
          }
          &:nth-child(n + 3) {
            flex-basis: 47%;
          }
          &:nth-child(n + 4) {
            flex-basis: 15%;
          }
          &:nth-child(n + 5) {
            flex-basis: 13%;
          }
        }
      }
    }
  }
}
