#hidden-close-button {
  // visibility: hidden;
  opacity: 0;
  z-index: -1;
  border: 1px solid;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  &:focus {
    // visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}
/* Menu box*/

.menu-box-header {
  @media print, screen and (max-width: 40em) {
    display: none;
  }

  @include breakpoint(small) {
    font-family: "Source Sans Pro", sans-serif;
    align-items: center;
    z-index: 99;
    width: 100%;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    margin-top: -990px;
    visibility: hidden;
    //transform: translateY(-150%);
    display: flex;
  }

  .button-plus-minus {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__inner {
    background: #fff;
    padding: 0;
    box-shadow: 3px 8px 12px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 0 auto;
    @include breakpoint(desktop) {
      flex-direction: row;
    }
    overflow: auto;
    height: 100%;
    @include breakpoint(large) {
      padding: 0;
      margin-top: 20px;
    }

    &__arrow {
      width: 20px;
      height: 20px;
      background: $mystic;
      position: absolute;
      left: 1093px;
      top: 10px;
      margin-top: -20px;
      transform: rotate(45deg);
      transition: 1s ease all;

      @include breakpoint(medium down) {
        display: none;
      }
    }

    .menu-content-left {
      // width: 900px;
      // @include breakpoint(desktop) {
      //   flex-basis: 70%;
      // }
      @include breakpoint(desktop) {
        flex-basis: 75%;
      }

      &__box-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px 25px 20px 35px;

        // padding: 20px 0px 20px 28px;
        max-height: 550px;
        overflow: auto;

        &__box {
          flex: 1 0 50%;
          padding: 30px 35px 0px 0px;
          // padding: 30px 28px 0px 0px;
          @media (max-width: 700px) {
            padding: 30px 25px 0 0;
          }

          &__inner {
            min-height: 160px;
            position: relative;
            /* Top wrapper */
            &__top-wrapper {
              display: flex;
              flex-direction: row;
              margin-bottom: 20px;

              button,
              .button-plus-minus {
                background: $gray;

                i {
                  // transform: rotate(90deg);
                  left: 50%;
                  right: 50%;
                  transition: 0.5s;
                  // transition: 0.5s all ease-out;
                }
                .icon-plus-1 {
                  opacity: 1;
                  transform: rotate(90deg);
                }
                .icon-minus-1 {
                  opacity: 1;
                  // transform: rotate(-180deg);
                }
              }

              &__toggle {
                font-family: "Source Sans Pro", sans-serif;
                font-size: 14px;
                line-height: 1.1em;
                font-weight: bold;
                color: $dark-blue;
                letter-spacing: 0.75px;
                text-transform: uppercase;
                padding: 5px 7px;
                height: 40px;
                display: flex;
                align-items: center;
                transition: 0.4s ease all;
                cursor: pointer;
                flex: 1 1 auto;
                background: linear-gradient(90deg, $gray 35%, $gray 100%);
                -webkit-transition: background 0.4s cubic-bezier(0.4, 0, 1, 1);
                -moz-transition: background 0.4s cubic-bezier(0.4, 0, 1, 1);
                -o-transition: background 0.4s cubic-bezier(0.4, 0, 1, 1);
                transition: background 0.4s cubic-bezier(0.4, 0, 1, 1);

                &:hover,
                &:focus {
                  span {
                    text-decoration: underline;
                  }
                  background: $gray;
                  background: linear-gradient(90deg, $gray 35%, #c8d2e1 100%);
                  background-position: 372px;
                }

                i {
                  margin-left: 5px;
                }
              }

              &__plusminus {
                border: 2px solid $dark-blue;
                border-radius: 50%;
                color: $dark-blue;
                height: 25px;
                width: 25px;
                display: flex;
                font-size: 16px;
                justify-content: center;
                align-items: center;
                margin: 5px 10px;
                cursor: pointer;
                transition: 0.2s ease all;

                &:hover,
                &:focus {
                  background: $dark-blue;
                  color: #fff;
                }
              }
            }

            &__text {
              color: $deep-gray;
              font-family: "Source Sans Pro", sans-serif;
              font-size: 14px;
              line-height: 1.29;
              overflow: hidden;
              opacity: 0;
              transition: 0.3s ease all;
              max-height: 0;
              padding-left: 10px;

              &[aria-hidden="false"] {
                overflow: visible;
                opacity: 1;
                max-height: 400px;
                transition-delay: 0.3s;
              }
            }

            &__link-wrapper {
              flex-direction: column;
              display: flex;
              overflow: hidden;
              // opacity: 0;
              transition: 0.3s ease all;
              // max-height: 0;
              bottom: 15px;
              position: relative;
              padding: 10px 0 0 15px;

              &[aria-hidden="false"] {
                overflow: visible;
                opacity: 1;
                max-height: 400px;
                transition-delay: 0.3s;
              }

              &__link {
                color: $deep-gray;
                font-size: 14px;
                width: fit-content;
                transition: 0.2s ease all;
                margin-bottom: 5px;
                border-bottom: 2px solid transparent;

                &:hover,
                &:focus {
                  border-color: $deep-gray;
                }
              }
            }
          }
        }
      }
    }

    .menu-content-right {
      @include breakpoint(desktop) {
        flex-basis: calc(25% + 1px);
        padding: 40px;
        flex-direction: column;

        justify-content: flex-start;
      }
      padding: 20px 70px 20px 40px;
      justify-content: space-between;
      flex-wrap: wrap;

      background: $mystic;
      display: flex;

      &__link-box {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;

        &:first-child {
          margin-top: 10px;
        }

        &__heading {
          font-size: 15px;
          color: $dark-blue;
          font-weight: bold;
          margin-bottom: 10px;
          span {
            border-bottom: 2px solid transparent;
            transition: 0.2s ease all;
          }
          &:hover {
            span {
              transition: 0.2s ease all;

              border-color: $dark-blue;
            }
          }
        }

        &__links {
          flex-direction: column;
          display: flex;
          flex-wrap: wrap;
          max-height: 100px;
          @include breakpoint(desktop) {
            max-height: unset;
            flex-wrap: nowrap;
          }
          &__link {
            color: $dark-gray;
            font-size: 14px;
            margin: 5px 40px 0 0;
            display: flex;
            align-items: center;
            transition: 0.2s ease all;

            @include breakpoint(desktop) {
              margin: 5px 0 0 0;
            }
            span {
              transition: 0.2s ease;
              border-bottom: 2px solid transparent;
            }

            &:before {
              font-family: "fontello";
              content: "\f105";
              display: flex;
              margin-right: 10px;
              background: $light-blue;
              color: #fff;
              font-size: 10px;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              justify-content: center;
              flex: 0 0 16px;
              transition: 0.2s ease all;
            }

            &:hover,
            &:focus {
              span {
                transition: 0.2s ease;
                border-color: $dark-gray;
              }
            }
          }
        }
      }
      &__icon-links {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 40px 0;

        @include breakpoint(desktop) {
          padding: 30px 0;
        }

        a {
          font-size: 13px;
          color: $dark-blue;
          transition: 0.2s ease all;
          display: flex;
          padding: 5px 0;
          width: fit-content;
          align-items: center;
          span {
            transition: 0.2s ease all;

            border-bottom: 2px solid transparent;
          }

          &:hover,
          &:focus {
            span {
              transition: 0.2s ease all;

              border-color: $dark-blue;
            }
          }

          i {
            font-size: 22px;
            margin-right: 5px;
            &:before {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
/*Styling for nested links*/
.expand-children-button {
  font-weight: bold;
  font-size: 14px;
  padding: 5px 0px;
  color: $deep-gray;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease all;
  margin-left: 10px;

  &:hover,
  &:focus {
    color: $light-blue;
    border-color: $light-blue;
  }

  &.closed-container {
    &:before {
      font-family: "fontello";
      content: "\e814";
      border-radius: 50%;
      border: 1px solid #000;
      height: 16px;
      width: 16px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.open-container {
    &:before {
      font-family: "fontello";
      content: "\e815";
      border-radius: 50%;
      border: 1px solid #000;
      height: 16px;
      width: 16px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.expanded-children {
  flex-direction: column;
  opacity: 0;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s ease all;
  margin-left: 10px;

  &[aria-hidden="false"] {
    display: flex;
    opacity: 1;
    overflow: visible;
    max-height: 400px;
  }

  a {
    font-size: 14px;
    color: $deep-gray;
    transition: 0.2s ease all;
  }

  &.expandable-inner-link {
    &.expand-children-button {
      margin-left: 20px;
    }
  }
}

.expanded-menu {
  @media (max-height: 1250px) {
    .menu-box-header__inner__arrow {
      display: none;
    }
  }
  height: 90vh;

  @include breakpoint(desktop) {
    height: unset;
  }

  display: flex;
  margin-top: 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.expandable-inner-link {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    color: #282625;
    font-size: 14px;
    transition: 0.2s ease all;
    border-bottom: 2px solid transparent;

    &:hover,
    &:focus {
      border-color: #282625;
    }
  }
}
/* Top wrapper - colors*/
/* Dark blue */
.menu-content-left__box-wrapper__box__inner__top-wrapper.color-dark-blue {
  .menu-content-left__box-wrapper__box__inner__top-wrapper__toggle {
    color: #fff;
    background: $dark-blue;
    background: linear-gradient(90deg, $dark-blue 35%, $dark-blue 100%);

    &:hover,
    &:focus {
      span {
        text-decoration: underline;
      }
      background: $dark-blue;
      background: linear-gradient(90deg, $dark-blue 35%, #002751 100%);
      background-position: 372px;
    }
  }

  button,
  .button-plus-minus {
    background: $dark-blue;
    i {
      // transform: rotate(90deg);
      left: 50%;
      right: 50%;
      transition: 0.4s;
      // transition: 0.5s all ease-out;
    }
    .icon-plus-1 {
      opacity: 1;
      transform: rotate(90deg);
    }
    .icon-minus-1 {
      opacity: 1;
      // transform: rotate(-180deg);
    }

    .menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus {
      color: #fff;
      border-color: #fff;

      &:hover,
      &:focus {
        color: $dark-blue !important;
        background: #fff;
      }
    }
  }
}
/* Medium blue */
.menu-content-left__box-wrapper__box__inner__top-wrapper.color-medium-blue {
  .menu-content-left__box-wrapper__box__inner__top-wrapper__toggle {
    // span {
    //   border-bottom: 1px solid transparent;
    // }
    color: #fff;
    background: $medium-blue;
    background: linear-gradient(90deg, $medium-blue 35%, $medium-blue 100%);

    &:hover,
    &:focus {
      span {
        text-decoration: underline;
      }
      background: $medium-blue;
      background: linear-gradient(90deg, $medium-blue 35%, #005068 100%);
      background-position: 372px;
    }
  }

  button,
  .button-plus-minus {
    background: $medium-blue;
    i {
      // transform: rotate(90deg);
      left: 50%;
      right: 50%;
      transition: 0.4s;
      // transition: 0.5s all ease-out;
    }
    .icon-plus-1 {
      opacity: 1;
      transform: rotate(90deg);
    }
    .icon-minus-1 {
      opacity: 1;
      // transform: rotate(-180deg);
    }

    .menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus {
      color: #fff;
      border-color: #fff;

      &:hover,
      &:focus {
        color: $medium-blue !important;
        background: #fff;
      }
    }
  }
}
/* Teal */
.menu-content-left__box-wrapper__box__inner__top-wrapper.color-teal {
  .menu-content-left__box-wrapper__box__inner__top-wrapper__toggle {
    color: #fff;
    background: linear-gradient(90deg, $teal 35%, $teal 100%);

    &:hover,
    &:focus {
      span {
        text-decoration: underline;
      }
      background: $teal;
      background: linear-gradient(90deg, $teal 35%, #006c5f 100%);
      background-position: 372px;
    }
  }

  button,
  .button-plus-minus {
    background: $teal;

    .menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus {
      color: #fff;
      border-color: #fff;

      &:hover,
      &:focus {
        color: $dark-blue !important;
        background: #fff;
      }
    }
  }
}
/* Olive */
.menu-content-left__box-wrapper__box__inner__top-wrapper.color-olive {
  .menu-content-left__box-wrapper__box__inner__top-wrapper__toggle {
    color: #fff;
    background: linear-gradient(90deg, $olive 35%, $olive 100%);

    &:hover,
    &:focus {
      span {
        text-decoration: underline;
      }
      background: $olive;
      background: linear-gradient(90deg, $olive 35%, #477214 100%);
      background-position: 372px;
    }
  }

  button,
  .button-plus-minus {
    background: $olive;

    .menu-content-left__box-wrapper__box__inner__top-wrapper__plusminus {
      color: #fff;
      border-color: #fff;

      &:hover,
      &:focus {
        color: $dark-blue !important;
        background: #fff;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .menu-box-header__inner .menu-content-left__box-wrapper__box {
    flex: 1 0 45%;
  }
  /*.menu-box-header__inner .menu-content-left{
    width:1200px;
  }*/
  /*@media print, screen and (min-width:79.93em)*/
  .outer-menu-content {
    width: 1200px !important;
  }
  /*.menu-content-left:first-of-type{
    width: 1200px;
  }*/
  // .menu-content-left__box-wrapper__box__inner__top-wrapper button {
  //   display: none;
  // }
}

.icon-plusminus,
.expand-children-button {
  i {
    // transform: rotate(90deg);
    left: 50%;
    right: 50%;
    transition: 0.4s;
    // transition: 0.5s all ease-out;
  }
  .icon-plus {
    transform: rotate(90deg);
  }
}
.open-container,
.closed-container {
  // transform: rotate(-90deg);
}
.open-container:before,
.closed-container:before {
  // transform: rotate(180deg);

  transition: 0.4s;
  opacity: 1;
}
.open-container:before {
  // transform: rotate(-90deg);
  transition: 0.4s;
  opacity: 1;
}
.closed-container:before {
  transform: rotate(90deg);
}
