.white {
  background: #fff !important;

  ul {
    color: #575757 !important;
  }

  .quote-block-inner-wrapper__text-wrapper__CTA {
    border-color: $dark-blue;
    &__link {
      &:hover {
        .icon-angle-right {
          background: $dark-blue;
          color: white;
        }
      }
      color: $dark-blue;
      &__icon {
        border-color: $dark-blue;
        background: transparent;
      }
    }
  }

  .text-block-wrapper__item__text-wrapper__preamble,
  .text-block-wrapper__CTA__link__text,
  .text-block-wrapper__CTA__link,
  .text-block-wrapper__item__text-wrapper__text {
    color: #575757;
  }

  .text-block-wrapper__CTA__link__icon {
    color: $dark-blue;
    border-color: $dark-blue;
  }
  &.c-contact-widget,
  .contact-widget-container__inner {
    h2,
    p,
    a,
    i,
    .contact-widget-container__inner__ingress {
      color: $dark-blue;
    }
  }

  .text-block__heading,
  .text-block-wrapper__top__heading,
  .text-block-wrapper__item__text-wrapper__heading,
  .c-image-links-block__wrapper__item__text-wrapper *,
  .c-image-links-block__heading,
  .c-image-links-block__ingress,
  .quote-block-inner-wrapper__text-wrapper__heading,
  .quote-block-inner-wrapper__text-wrapper__bottom-row__content *,
  .text-block__heading,
  .text-block-wrapper__top__heading,
  .text-block-wrapper__item__text-wrapper__heading,
  .quote-block-inner-wrapper__text-wrapper__CTA__link__text,
  .quote-block-inner-wrapper__text-wrapper__CTA__link__icon {
    color: $dark-blue;
  }

  .c-image-links-block__ingress {
    a {
      color: $dark-blue;
      border-bottom: 2px solid $dark-blue;
    }
  }

  .content-editorial a {
    color: $dark-blue;
    border-bottom: 2px solid transparent;
    transition: 0.3s ease all;

    &:hover {
      transition: 0.3s ease all;

      border-color: $dark-blue;
    }
  }

  .content-editorial a[href*="http"]:not([href*="pdf"])::after,
  .c-image-links-block__wrapper__item__text-wrapper__ingress
    a[href*="http"]:not([href*="pdf"])::after {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    color: $dark-blue;
    display: inline-block;

    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    content: "\f08e";
    /* font-size: 120%; */
    -webkit-font-smoothing: antialiased;
  }

  .text-block-wrapper__CTA {
    border-color: $dark-blue;

    &:hover,
    &:focus {
      .text-block-wrapper__CTA__link__text {
        color: #575757;
      }

      // .text-block-wrapper__CTA__link__icon {
      //   background: $light-blue;
      //   color: #fff;
      //   border-color: $light-blue;
      // }
    }
  }
}
