.module-footer {
  a {
    border-bottom: 2px solid transparent;
    &:hover {
      border-color: $dark-blue;
    }
  }
  font-family: "Source Sans Pro", sans-serif;
  background-color: $mystic;
  color: $dark-blue;
  font-size: 20px;
  line-height: 1.5;

  p {
    font-family: "Source Sans Pro", sans-serif;
    color: $dark-blue;
    font-size: 20px;
  }

  & strong,
  & b {
    font-weight: 600;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    &__top-wrapper {
      padding: 40px 0px;
      margin: 0 auto;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include breakpoint(medium) {
        padding: 90px 40px;
        flex-direction: row;
      }
      @include breakpoint(large) {
        padding: 90px 0px;
      }

      &__item {
        padding: 20px 20px;

        @include breakpoint(small) {
          flex: 1 1 50%;
        }

        @include breakpoint(large) {
          padding: 0;
          width: 25%;
          flex: auto;
        }

        p {
          margin-bottom: 0;
        }

        h2 {
          font-size: 24px !important;
          font-weight: bold;
          margin-bottom: 30px;

          i {
            font-size: 28px;
            margin-right: 8px;

            &:before {
              margin-left: 0;
            }
          }
        }

        ul {
          list-style: none;
          margin-left: 0;
          padding-left: 0;
          li {
            @include breakpoint(large) {
              font-size: 0.9em;
            }
          }

          a {
            color: $dark-blue;
            transition: 0.3s ease all;

            // &:hover,
            // &:focus {
            //   color: $light-blue;
            // }
          }

          .social-logo {
            // border-color: rgba(0, 0, 0, 0) !important;

            i {
              margin-right: 15px;
              font-size: 22px;
            }
          }
        }
      }
    }

    &__bottom-wrapper {
      background-color: rgba($dark-blue, 0.1);

      @include breakpoint(medium) {
        height: 120px;
      }

      &__inner {
        display: block;
        align-items: center;
        padding: 0;
        align-items: stretch;
        height: 100%;

        @include breakpoint(medium) {
          display: flex;
          padding: 0;
        }

        &__left {
          flex: 1 1 auto;
          display: flex;
          align-items: center;
          margin: 20px 0;

          @include breakpoint(medium) {
            padding: 0;
            width: 70%;
            flex-basis: 70%;
            flex-grow: 0;
            margin: 20px 60px;
          }

          @include breakpoint(large) {
            margin-left: 0px;
          }

          &__item {
            @include breakpoint(medium) {
              display: flex;
              align-items: center;
            }

            & p {
              margin: 20px;

              @include breakpoint(medium) {
                margin-right: 40px;
                margin-left: 0;
              }

              @include breakpoint(large) {
                font-size: 20px;
              }
            }
          }
        }

        &__right {
          width: 100%;
          align-items: center;
          display: flex;
          background: #cfd8e6;
          padding: 10px 0;

          @include breakpoint(medium) {
            width: 30%;
            flex-basis: 30%;
            // margin: 0 20px;
            // padding: 0 0 0 35px;
            background: linear-gradient(to right, #cfd8e6, transparent);
          }

          @include breakpoint(large) {
            // padding-left: 70px;
          }

          &__logo {
            height: auto !important;
            width: 80% !important;
            margin: 20px;

            @include breakpoint(medium) {
              margin: 0;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
